import { Buffer } from 'buffer';
import { saveStateAndVerifier } from './session-helper';
export { getCodeVerifier, getState } from './session-helper';

const generateState = () => generateRandomString(64);

const generateCodeVerifier = () => generateRandomString(128);

export const generateCodeChallenge = async () => {
  const codeVerifier = generateCodeVerifier();
  const state = encodeURIComponent(generateState());
  saveStateAndVerifier(state, codeVerifier);
  // RFC#7636: code_challenge = BASE64URL-ENCODE(SHA256(ASCII(codeVerifier)))
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier); // ASCII(codeVerifier) / UTF-8
  const digest = await window.crypto.subtle.digest('SHA-256', data); //SHA256(ASCII(codeVerifier))
  const toBase64 = Buffer.from(digest).toString('base64'); //BASE64(SHA256(ASCII(codeVerifier)))
  return toBase64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};

const generateRandomString = (length) => {
  /* RFC#7636 code_verifier = high-entropy cryptographic random STRING using the
    unreserved characters [A-Z] / [a-z] / [0-9] / "-" / "." / "_" / "~"
    from Section 2.3 of [RFC3986], with a minimum length of 43 characters
    and a maximum length of 128 characters. */
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};
