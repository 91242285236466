import React from 'react';

export const NavShortcut = ({
  link,
  icon,
  text,
}: {
  link: string;
  icon: string;
  text: string;
}) => (
  <li className='navshortcuts__item'>
    <a className='navshortcuts__link' href={link}>
      <figure className='navshortcuts__icon'>
        <svg className='icon' aria-hidden='true'>
          <use xlinkHref={`#navigation--shortcuts--${icon}`}></use>
        </svg>
      </figure>
      <span className='navshortcuts__text'>{text}</span>
    </a>
  </li>
);
