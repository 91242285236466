import {
  NetCrackerSubscriptionType,
  SubscriptionAddressType,
} from 'api/netCrackerAPI/netCrackerApiTypes';
import { getConfig } from 'shared/config';
import { fetchColumbusSubscriptionDetails } from './useMyColumbusSubscriptionsService';
import {
  ColumbusSubscriptionRawDataInterface,
  ColumbusSubscriptionType,
} from './useMyColumbusSubscriptionsTypes';

// Don't render a nav-item for certain products.
export const ignoreProducts = ['NetBasis'];

export const fetchColumbusSubscriptionsDetails = async (
  rawSubscriptionList: ColumbusSubscriptionRawDataInterface[],
): Promise<ColumbusSubscriptionType[]> => {
  const detailsPromiseList: Promise<ColumbusSubscriptionRawDataInterface>[] = [];
  let subscriptionList: ColumbusSubscriptionType[] = [];
  const groupCount = countSubscriptionsGroups(rawSubscriptionList);

  rawSubscriptionList.forEach((subscription) => {
    if (includeSubscriptionDetails(subscription, groupCount)) {
      const columbusSubscriptionResponse = fetchColumbusSubscriptionDetails(subscription);
      detailsPromiseList.push(columbusSubscriptionResponse);
    } else {
      subscriptionList.push(new Subscription(subscription));
    }
  });

  try {
    const resolvedSubscriptions = await Promise.all(detailsPromiseList);
    resolvedSubscriptions.forEach((resolvedSubscription) => {
      if (resolvedSubscription) {
        const subscriptionWithDetails = new Subscription(resolvedSubscription);
        subscriptionList.push(subscriptionWithDetails);
      }
    });
  } catch (error) {
    // If we get an error, we still want to show the subscriptions without details
    subscriptionList = [];
    subscriptionList.push(
      ...rawSubscriptionList.map((subscription) => new Subscription(subscription)),
    );
  }

  return subscriptionList;
};

type Keys = 'Broadband' | 'Fixednet' | 'Mobile' | 'Tv';
export type GroupCountType = {
  [key in Keys]?: number;
};

export const countSubscriptionsGroups = (
  productList: ColumbusSubscriptionRawDataInterface[],
): GroupCountType => {
  const groupCount: GroupCountType = {};
  productList.forEach((productData: ColumbusSubscriptionRawDataInterface) => {
    // Count the productTypes
    groupCount[productData.productType] = groupCount[productData.productType]
      ? groupCount[productData.productType] + 1
      : 1;
  });
  return groupCount;
};

export const includeSubscriptionDetails = (subscription, groupCount): boolean => {
  // We must have subscriptions
  if (!subscription) {
    return false;
  }
  // If only one BB or TV, do not request details for address, but do not remove the subscription
  if (
    ['Broadband', 'Tv'].indexOf(subscription.productType) !== -1 &&
    groupCount[subscription.productType] <= 1
  ) {
    return false;
  }

  // If productType is ignored, no details needs to be shown
  if (ignoreProducts.some((product) => product === subscription.productType)) {
    return false;
  }
  return true;
};

export function Subscription(
  this: ColumbusSubscriptionType,
  data: ColumbusSubscriptionRawDataInterface,
) {
  this.isNetCrackerSubscription = false;
  this.phoneNumber = data.phoneNumber && data.phoneNumber;

  /**
   * Get the product name.
   * @return {String} The product name
   */
  this.productName = getProductName(data.productType);

  /**
   * Get the product type.
   * @return {String} Subscription product productType
   */
  this.productType = getProductType(data.productType);

  /**
   * Get the subscription ID
   * @return {String} Subscription id
   */
  this.subscriptionId = data.subscriptionId || '';

  /**
   * Get the subscription address.
   * @return {String} The address.
   */
  this.getAddress = (): string => getAddress(data.address);

  /**
   * Does this subscription have a phone number.
   * @return {Boolean}
   */
  this.hasPhoneNumber = (): boolean => hasPhoneNumber(this);

  /**
   * Get the subscription phone number.
   * @return {String} The phone number.
   */
  this.getPhoneNumber = (): string => getPhoneNumber(this, this.phoneNumber);

  /**
   * Get the subscription phone number.
   * @return {String} The phone number.
   */
  this.getUrl = (): string => getUrl([], this.productType, this, this.isNetCrackerSubscription);

  /**
   * Assign all properties from netcracker subscription.
   * @return {String} The phone number.
   */
}

const productTypeMap = {
  Fixednet: 'Fastnet telefon',
  Mobile: 'Mobil',
  MobileBroadband: 'Mobilt bredbånd',
  Broadband: 'Bredbånd',
  NetBasis: 'Mailabonnement',
  Tv: 'Tv & Streaming',
};

export const getUrl = (prefixMap, productType, localData, isNetcracker) => {
  let prefix = '';
  if (prefixMap[productType]) {
    prefix = prefixMap[productType].toLowerCase();
  }
  // Sitecore external URLs does not necessarely start with an /
  if (prefix.slice(0, 1) !== '/') {
    prefix = '/' + prefix;
  }

  const { baseUrl, newMobileURL, newBroadbandURL, newLandlineURL, newTvURL } =
    getConfig().navigation || {};

  const netCrackerHost = getConfig().netcracker?.host;

  if (newTvURL && prefix === '/tv') return `${prefix}/${localData.subscriptionId}`;
  if (newBroadbandURL && prefix === '/bredbaand') return `${prefix}/${localData.subscriptionId}`;
  if (newLandlineURL && prefix === '/fastnet') return `${prefix}/${localData.subscriptionId}`;
  if ((newMobileURL && prefix === '/mobil') || prefix === '/mobiltbredbaand') {
    return !isNetcracker
      ? `${prefix}/${localData.subscriptionId}`
      : `${netCrackerHost}/ecare/products/${localData.subscriptionId}/usage`;
  }
  return `${baseUrl}${prefix}#!/abonnement/${localData.subscriptionId}`;
};

export const getProductName = (productType: string): string => {
  return productTypeMap[productType] || '';
};

export const getProductType = (productType: string | undefined): string => {
  return productType || '';
};

export const getAddress = (address: SubscriptionAddressType | undefined): string => {
  return address ? `${address.street} ${address.streetNumber}` : '';
};

export const hasPhoneNumber = (subscription): boolean => {
  return subscription.hasOwnProperty('phoneNumber') || subscription.productType === 'Mobile';
};

export const getPhoneNumber = (
  subscription: NetCrackerSubscriptionType | ColumbusSubscriptionType,
  value: string | undefined,
): string => {
  // Get phone number
  const ifOnlyNumberRegex = new RegExp(/^[0-9]{8}$/g);
  return subscription.hasPhoneNumber() && value && ifOnlyNumberRegex.exec(value)
    ? `${value}`.replace(/(\S{2})/g, '$1 ')
    : '';
};

export const getIconName = (productType: string): string => {
  let iconName = productType;

  // Setup special cases.
  iconName = iconName === 'Fixednet' ? 'phone' : iconName;
  iconName = iconName === 'NetBasis' ? 'mail' : iconName;

  // Lowercase others
  iconName = iconName.toLowerCase();
  iconName = iconName.replace('_', '-');
  return iconName;
};
