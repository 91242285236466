import originalLoadable from '@loadable/component';

type Options = {
  retries?: number;
  interval?: number;
  exponentialBackoff?: boolean;
};

const LOCAL_STORAGE_KEY = 'reload';

// taken from https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
function retry<R>(
  fn: () => Promise<R>,
  { retries = 3, interval = 500, exponentialBackoff = true }: Options = {},
) {
  return new Promise<R>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        // check reloadTimer state
        let reloadTimer = sessionStorage?.getItem(LOCAL_STORAGE_KEY);
        // Delete reloadTimer if it is older than 1 minutes
        if (reloadTimer && new Date(reloadTimer).getTime() < new Date().getTime() - 1000 * 60) {
          sessionStorage?.removeItem(LOCAL_STORAGE_KEY);
          reloadTimer = null;
        }

        // If the page has been redeployed the chunks has changed and the old ones are no longer available
        // instead an 'text/html' is returned. Therefore, we can reload the page to get the new chunks.
        const testableError = error?.toString()?.toLowerCase();
        if (
          (testableError?.includes('failed to asynchronously load component') ||
            testableError?.includes('importing a module script failed') ||
            testableError?.includes('typeerror: error loading dynamically imported module') ||
            testableError?.includes('failed to fetch dynamically imported module') ||
            testableError?.includes('is not a valid javascript mime type')) &&
          !reloadTimer
        ) {
          sessionStorage?.setItem(LOCAL_STORAGE_KEY, new Date().toISOString());
          location.reload();
          return;
        }
        setTimeout(() => {
          if (retries === 1) {
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, {
            retries: retries - 1,
            interval: exponentialBackoff ? interval * 2 : interval,
          }).then(resolve, reject);
        }, interval);
      });
  });
}

// This wrapper is implemented because of a safari 12 for ios bug that is failing to load chunks
const loadable = (fn) => {
  return originalLoadable(() => retry(fn, { retries: 3 }));
};

export default loadable;
