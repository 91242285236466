import { Box, Button, Container, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';

const ErrorPage: FC = () => {
  useEffect(() => {
    document.body.classList.remove('has-navpanel-left');
  }, []);

  return (
    <Container
      maxWidth='lg'
      sx={{
        marginTop: '4rem',
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          margin: 'auto',
          paddingBlock: '5rem 0rem',
          maxWidth: { xs: '320px', sm: '576px', md: '768px' },
        }}>
        <Typography variant='h2'>
          Den side du har forsøgt at åbne findes ikke. Tryk her for at komme til vores forside
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          margin: 'auto',
        }}>
        <Button
          variant='cta'
          href='https://yousee.dk'
          target='_self'
          sx={{
            margin: '4rem auto',
          }}>
          Gå til forsiden
        </Button>
      </Box>
    </Container>
  );
};

export default ErrorPage;
