// @ts-nocheck
import { TimelineLite } from 'gsapOld';

function AnimatedNavPanelLinks() {
  const tl = new TimelineLite({ delay: 0.25 });

  const animatedLinkItems = [].slice.call(
    document.querySelectorAll(
      '.navpanel--selfcare > .navpanel__body > .navpanel-items-level0 > .navpanel__item',
    ),
  );
  const animatedShortcutItems = [].slice
    .call(
      document.querySelectorAll(
        '.navpanel--selfcare > .navpanel__body > .navshortcuts > .navshortcuts__item',
      ),
    )
    .reverse();
  const mergedAnimatedItems = animatedLinkItems.concat(animatedShortcutItems);

  tl.staggerFrom(
    mergedAnimatedItems,
    0.3,
    { x: -80, opacity: 0, ease: window.Back.easeOut, clearProps: 'all' },
    0.04,
  );

  //Make the duration on the last two items run slower
  const animatedItemsReverse = tl.getChildren();
  animatedItemsReverse.pop().duration(0.7); //last
  animatedItemsReverse.pop().duration(0.5); //2nd to last
}

export default AnimatedNavPanelLinks;
