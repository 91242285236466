export const isObject = (item: any) => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

export const isArray = (item: any) => {
  return item && typeof item === 'object' && Array.isArray(item);
};

export const mergeDeep = (target: object, ...sources: object[]) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (!isObject(target) || !isObject(source)) {
    return mergeDeep(target, ...sources);
  }

  for (const key in source) {
    const IsObj = isObject(source[key]);
    if (target[key] === undefined) target[key] = IsObj ? {} : source[key];
    if (IsObj) {
      mergeDeep(target[key], source[key]);
    }
    if (isArray(source[key]) && isArray(target[key]))
      target[key] = [...source[key], ...target[key]];
  }

  return mergeDeep(target, ...sources);
};

export const shallowEquals = (source: object, target: object) => {
  const sourceProps = Object.getOwnPropertyNames(source);
  const targetProps = Object.getOwnPropertyNames(target);
  if (sourceProps.length !== targetProps.length) {
    return false;
  }
  for (let i = 0; i < sourceProps.length; i++) {
    const propName = sourceProps[i];
    if (source[propName] !== target[propName]) {
      return false;
    }
  }
  return true;
};

export const deepEquals = (a: any, b: any) => {
  if (a === b) return true;
  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (a.constructor !== b.constructor) return false;
    let length, i;
    if (Array.isArray(a)) {
      length = a.length;
      // eslint-disable-next-line eqeqeq
      if (length != b.length) return false;
      for (i = length; i-- !== 0; ) if (!deepEquals(a[i], b[i])) return false;
      return true;
    }
    if (a instanceof Map && b instanceof Map) {
      if (a.size !== b.size) return false;
      // @ts-ignore
      for (i of a.entries()) if (!b.has(i[0])) return false;
      // @ts-ignore
      for (i of a.entries()) if (!deepEquals(i[1], b.get(i[0]))) return false;
      return true;
    }
    if (a instanceof Set && b instanceof Set) {
      if (a.size !== b.size) return false;
      // @ts-ignore
      for (i of a.entries()) if (!b.has(i[0])) return false;
      return true;
    }
    if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags;
    if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
    if (a.toString !== Object.prototype.toString) return a.toString() === b.toString();
    const keys = Object.keys(a);
    length = keys.length;
    if (length !== Object.keys(b).length) return false;
    for (i = length; i-- !== 0; )
      if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;
    for (i = length; i-- !== 0; ) {
      const key = keys[i];
      if (!deepEquals(a[key], b[key])) return false;
    }
    return true;
  }
  return a !== a && b !== b;
};
