/**
 * Get which event to listen for.
 * Browser polyfill.
 */
function transitionEndEvent() {
  let t;
  const el = document.createElement('void');
  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };

  for (t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
}

export default transitionEndEvent();
