import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { colors } from '@nuuday/yousee-dna/generated/colors';
import React from 'react';

import { isUserAgent } from 'utils/user-agent';
import PageHeaderBackLink, { PageHeaderBackLinkProps } from './PageHeaderBackLink';
import { pageHeaderMobileSx, pageHeaderSx } from './styles';

type PageHeaderProps = {
  backLinkProps?: PageHeaderBackLinkProps;
  notifications?: React.ReactNode;
  subheader?: React.ReactNode;
  title: React.ReactNode;
};

export const PageHeader = ({
  oldDesignNotification,
  backLinkProps,
  notifications,
  subheader,
  title,
}: { oldDesignNotification?: React.ReactNode } & PageHeaderProps) => {
  const isAnyMobileApp = isUserAgent(['youseeSelfcareApp', 'netcrackerApp']);

  return (
    <>
      {React.isValidElement(oldDesignNotification) && !isAnyMobileApp && (
        <Box sx={{ background: colors.White, pt: 5, mb: -3 }}>
          <Container maxWidth='md'>{oldDesignNotification}</Container>
        </Box>
      )}
      <Box sx={isAnyMobileApp ? pageHeaderMobileSx : pageHeaderSx}>
        <Container maxWidth='md'>
          {backLinkProps && !isAnyMobileApp && <PageHeaderBackLink {...backLinkProps} />}
          {notifications}
          <Typography
            variant='h1'
            sx={{
              hyphens: 'auto',
              mb: subheader ? 1 : 0,
              textAlign: isAnyMobileApp ? 'center' : undefined,
              ...(!isAnyMobileApp ? { mt: 5 } : {}),
              fontSize: { xs: '26px', md: '40px' },
            }}>
            {title}
          </Typography>
          {subheader && (
            <Typography
              variant='regular16'
              sx={{ pt: 2, textAlign: isAnyMobileApp ? 'center' : undefined }}>
              {subheader}
            </Typography>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PageHeader;
