import { getFeatureToggle } from 'utils/feature-toggle';
import { featLoginWithCiamPing } from 'utils/feature-toggle/feat-login-with-ciam-ping';
import { StackNames, getStackSimulated } from 'utils/misc';
import { getParsedToken } from 'utils/misc/getParsedToken';

export const getStack = (): StackNames => {
  const defaultStack = StackNames.COLUMBUS;
  if (getFeatureToggle({ name: 'feat-simulate-stack' }) && featLoginWithCiamPing()) {
    return getStackSimulated(defaultStack);
  }

  const parsedToken = getParsedToken();
  if (!parsedToken) {
    return defaultStack;
  }

  const { individual_id: netcrackerStackIndicator, linkItId, linkitid } = parsedToken || {};

  const columbusStackIndicator = linkItId || linkitid;

  if (netcrackerStackIndicator && !columbusStackIndicator) {
    return netcrackerStackIndicator ? StackNames.NETCRACKER : defaultStack;
  }

  if (columbusStackIndicator && !netcrackerStackIndicator) {
    return columbusStackIndicator ? StackNames.COLUMBUS : defaultStack;
  }

  if (netcrackerStackIndicator && columbusStackIndicator) {
    return Boolean(netcrackerStackIndicator) && Boolean(columbusStackIndicator)
      ? StackNames.DUAL
      : defaultStack;
  }

  return defaultStack;
};
