import { NetCrackerSubscriptionType } from 'api/netCrackerAPI/netCrackerApiTypes';
import { ColumbusSubscriptionType } from 'hooks/useMyColumbusSubscriptions/useMyColumbusSubscriptionsTypes';
import {
  countSubscriptionsGroups,
  ignoreProducts,
} from 'hooks/useMyColumbusSubscriptions/useMyColumbusSubscriptionsUtils';
import React, { FC } from 'react';
import { SubscriptionsNavItem } from './SubscriptionsNavItem';

type SubscriptionsNavItemsProps = {
  subscriptions: (NetCrackerSubscriptionType | ColumbusSubscriptionType)[];
};

const dataOrder = 'Mobile,Broadband,Tv,Fixednet,MobileBroadband';

export const SubscriptionsNavItems: FC<SubscriptionsNavItemsProps> = ({ subscriptions }) => {
  // Get the ordering
  if (dataOrder) {
    const orderArray = dataOrder.split(',').reverse();
    // Order subscriptions
    orderArray.forEach((orderItem) => {
      subscriptions.forEach((subscription, idx) => {
        if (orderItem === subscription.productType) {
          subscriptions.splice(idx, 1);
          subscriptions.unshift(subscription);
        }
      });
    });
  }

  const groupCount = countSubscriptionsGroups(subscriptions);

  const subscriptionsLength = subscriptions.length;

  return (
    <>
      {subscriptions?.map(
        (subscription, index) =>
          !ignoreProducts.includes(subscription.productType) && (
            <SubscriptionsNavItem
              key={index}
              subscription={subscription}
              index={index}
              subscriptionsLength={subscriptionsLength}
              groupCount={groupCount}
            />
          ),
      )}
    </>
  );
};
