import React, { FC } from 'react';

export const LeftNavSubscriptionsError: FC = () => {
  return (
    <div className='navpanel__item navpanel__item--error'>
      <figure className='navpanel__item-icon'>
        <svg className='icon'>
          <title>Der er sket en fejl</title>
          <use href='#navigation--mityousee--error'></use>
        </svg>
      </figure>
      Alle dine abonnementer kunne desværre ikke hentes. Prøv igen senere
    </div>
  );
};
