const buildText = (text: string, placeholderData: { [key: string]: string | number } = {}) => {
  for (const key in placeholderData)
    text = text?.replace(new RegExp(`{{\\s*${key}\\s*}}`, 'igm'), placeholderData[key] as string);
  return text;
};

const firstCharToLowercase = (string: string) => string.charAt(0).toLowerCase() + string.slice(1);

const firstCharToUpperCase = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const chunkString = (str: string, length: number) =>
  str.match(new RegExp('.{1,' + length + '}', 'g')) || [];

const twoByTwo = (string: string) => chunkString(string, 2).join(' ');

export const strings = {
  buildText,
  firstCharToLowercase,
  firstCharToUpperCase,
  chunkString,
  twoByTwo,
};
