// BaseURL
const MITID_API_BASE_URL_STAGE = 'https://mitid.stag.id.yousee.dk/v1/mitid';
const MITID_API_BASE_URL_PRODUCTION = 'https://mitid.id.yousee.dk/v1/mitid';
export const MITID_BASE_URL =
  process.env.NODE_ENV !== 'production' ? MITID_API_BASE_URL_STAGE : MITID_API_BASE_URL_PRODUCTION;

// Query params items
export const MITID_QUERY_SUCCESS = 'code';
export const MITID_QUERY_ERROR = 'error';

// MitID error messages
export const MITID_ERROR = {
  user_cancellation: {
    id: 'user_cancellation',
    type: 'info',
    text: 'Login forsøg afbrudt.',
  },
  invalid_cpr: {
    id: 'invalid_cpr',
    type: 'warning',
    text: 'Det cpr-nummer, du har indtastet, passer ikke med abonnementet. Kan det være et andet cpr-nummer?',
  },
  access_denied: {
    id: 'access_denied',
    type: 'warning',
    text: 'Noget gik galt. Prøv igen.',
  },
  invalid_credentials: {
    id: 'invalid_credentials',
    type: 'warning',
    text: 'Noget gik galt. Prøv igen.',
  },
  delegated_session_expired: {
    id: 'delegated_session_expired',
    type: 'warning',
    text: 'Noget gik galt. Prøv igen.',
  },
  default: {
    id: 'default',
    type: 'warning',
    text: 'Noget gik galt. Prøv igen.',
  },
};

// Labels
export const labels = {
  mitID_error: 'mitID_Error',
};
