import { useEffect, useState } from 'react';
import { identity } from 'utils/identity';
import {
  getFromLocalStorage,
  getFromSessionStorage,
  removeFromLocalStorage,
  setInSessionStorage,
} from 'utils/storage';
import Request from '../lib/request';
import { getUserId } from '../lib/user';

export const useEmailSettings = () => {
  const key = getUserId() + '-hasSubscription';
  const [hasEmailSubscription, setHasEmailSubscription] = useState<boolean | null>(
    getFromSessionStorage(key) || null,
  );

  if (getFromLocalStorage(key)) {
    // clear legacy implementation
    removeFromLocalStorage(key);
  }

  useEffect(() => {
    if (identity.isLoggedIn && hasEmailSubscription === null) {
      Request(
        'emailaccess',
        (emailAccess) => {
          setInSessionStorage(getUserId() + '-hasSubscription', emailAccess.hasSubscription);
          setHasEmailSubscription(emailAccess.hasSubscription);
        },
        (err) => {
          console.warn('Navigation: useEmailSettings - emails/hassubscription failed', err);
        },
      );
    }
  }, [hasEmailSubscription]);

  return { hasEmailSubscription };
};
