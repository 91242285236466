import { Config, ConfigProps } from 'config';
import { mergeDeep } from 'shared/utils/objects';
import { local } from './utils/storage/local-storage';

export let config: Config = {};

export const getConfig = () => config;

export const setConfig = (configObj: ConfigProps, stage: 'staging' | 'production') => {
  const overrides = local.get('configOverrides');
  config = mergeDeep(overrides || {}, configObj.sets[stage] || {}, configObj.sets.default, config);
};
