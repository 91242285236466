import { ACCESS_TOKEN } from 'shared/constants/cookies';
import { cookies } from 'shared/utils/storage/cookies';
import { getFeatureToggle } from 'utils/feature-toggle/index';
import { isMobileApp, isNetcrackerApp } from 'utils/user-agent';

export const featLoginWithCiamPing = (): boolean => {
  if (isMobileApp) {
    return false;
  }

  if (isNetcrackerApp) {
    return true;
  }

  const issuer = cookies
    .get(ACCESS_TOKEN, true)
    ?.includes('Issuer=yspro-customer-agent-impersonation-prod-v1');
  if (issuer) {
    return false;
  }

  return getFeatureToggle({
    name: 'feat-login-with-ciam-ping',
    enabledByDefault: true,
    allowedInProduction: true,
  });
};

export const featMitIdCiam = (): boolean => {
  if (isMobileApp) {
    return false;
  }

  if (isNetcrackerApp) {
    return false;
  }

  const issuer = cookies
    .get(ACCESS_TOKEN, true)
    ?.includes('Issuer=yspro-customer-agent-impersonation-prod-v1');
  if (issuer) {
    return false;
  }

  return getFeatureToggle({
    name: 'feat-MitID-CIAM',
    enabledByDefault: false,
    allowedInProduction: true,
  });
};
