import { ACCESS_TOKEN } from 'shared/constants/cookies';
import { cookies } from 'shared/utils/storage/cookies';
import { CIAMToken, parseJwt } from '../auth';

export const getParsedToken = (): CIAMToken | undefined => {
  const token = cookies.get(ACCESS_TOKEN, true);
  if (!token) {
    return undefined;
  }
  if (!parseJwt(token)) return undefined;
  const parsedToken = parseJwt(token);
  return parsedToken ?? undefined;
};
