// localStorage and sessionStorage is not available in all browsers, so we need to check for it
// before using it.
const localStorageAvailable = (): boolean => {
  try {
    const x = '__storage_test__';
    localStorage.setItem(x, x);
    localStorage.getItem(x);
    localStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

export const getFromLocalStorage = (key: string): any => {
  if (!localStorageAvailable()) {
    return null;
  }
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

export const setInLocalStorage = (key: string, value: any): void => {
  if (!localStorageAvailable()) {
    return;
  }
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeFromLocalStorage = (key: string): void => {
  if (!localStorageAvailable()) {
    return;
  }
  localStorage.removeItem(key);
};

const sessionStorageAvailable = (): boolean => {
  try {
    const x = '__storage_test__';
    sessionStorage.setItem(x, x);
    sessionStorage.getItem(x);
    sessionStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

export const getFromSessionStorage = (key: string): any => {
  if (!sessionStorageAvailable()) {
    return null;
  }
  const value = sessionStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

export const setInSessionStorage = (key: string, value: any): void => {
  if (!sessionStorageAvailable()) {
    return;
  }
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const removeFromSessionStorage = (key: string): void => {
  if (!sessionStorageAvailable()) {
    return;
  }
  sessionStorage.removeItem(key);
};
