import { cookies } from 'shared/utils/storage/cookies';
import { featLoginWithCiamPing } from 'utils/feature-toggle/feat-login-with-ciam-ping';
import { getLinkItId } from 'utils/misc';

export const getUserId = () => {
  if (featLoginWithCiamPing()) {
    return getLinkItId();
  }
  let token = cookies.get('access_token', true);
  let userId;

  if (token) {
    token = token.substring(1).split('&');
    let pair;

    for (let i = 0; i < token.length; i++) {
      pair = token[i].split('=');
      if (pair[0] === 'UserId') {
        userId = pair[1];
        break;
      }
    }
  }

  if (!userId) {
    userId = cookies.get('TestUserName', true);
  }
  return userId;
};
