import cookieLib from 'js-cookie';
import { getExpirationDate, hasExpired } from './dates';

const set = (key: string, data: any, expireInHours?: number, options?: any) => {
  try {
    cookieLib.set(key, data, {
      expires: expireInHours ? getExpirationDate(expireInHours) : null,
      secure: 'true',
      SameSite: 'None',
      ...options,
    });
  } catch (error) {
    console.warn(error);
  }
};

const get = (key: string, plainText?: boolean) => {
  try {
    if (plainText) {
      return cookieLib.get(key);
    }
    const parsed: { data: any; expirationDate: Date } = JSON.parse(cookieLib.get(key) || 'null');
    if (!parsed) return null;
    if (hasExpired(parsed.expirationDate)) return cookieLib.remove(key);
    return parsed.data;
  } catch (error) {
    console.warn(error);
    return null;
  }
};

const remove = (key: string, options: any = undefined) => {
  try {
    cookieLib.remove(key, options);
  } catch (error) {
    console.warn(error);
  }
};

export const cookies = { get, set, remove };
