import Request from '../lib/request';

// Setup subscriptioins when the dom is ready.
function UserPlugin() {
  // Check for data-type = subscriptions.
  const domElement = document.querySelector('li[data-type="user"]');
  if (domElement) {
    Request(
      'userprofile',
      (userprofile) => {
        // Just return if no accounts - don't make a big deal out of it.
        if (!userprofile.userName) {
          return;
        }

        let itemLink = domElement.firstElementChild;

        // if the element is supposed ot be <a> tag, replace the div
        if (itemLink?.getAttribute('href')) {
          const link = document.createElement('a');
          link.setAttribute('href', itemLink.getAttribute('href') || '');
          link.setAttribute('class', itemLink.getAttribute('class') || '');
          itemLink.parentNode?.replaceChild(link, itemLink);
          itemLink = link;
        }

        const itemLinkEmail = document.createElement('span');
        itemLinkEmail.setAttribute('class', 'navpanel__item-supertext');
        itemLinkEmail.innerHTML = 'Brugernavn:';
        itemLink?.appendChild(itemLinkEmail);

        const itemUserName = document.createElement('span');
        itemUserName.setAttribute('class', 'navpanel__item-usertext');
        itemUserName.innerHTML = userprofile.userName;
        itemUserName.setAttribute('data-hj-suppress', '');
        itemLink?.appendChild(itemUserName);

        // Add the icon
        itemLink?.insertAdjacentHTML(
          'afterbegin',
          `
        <figure class="navpanel__item-icon" aria-hidden="true">
          <svg class="navpanel__item-icon-svg">
            <use xlink:href="#navigation--mityousee--profile"></use>
          </svg>
        </figure>`,
        );

        domElement.setAttribute('class', 'navpanel__item navpanel__item--user-plugin');
      },
      (err) => {
        console.warn(err);

        // Remove initial item
        domElement.parentNode?.removeChild(domElement);
      },
    );
  }
}

export default UserPlugin;
