import { Box, Container, Typography } from '@mui/material';

import { Card } from 'components/Card';
import { CardContent } from 'components/Card/CardContent';
import PageHeader from 'components/PageHeader';
import { NoConnectionSvg } from 'components/img/NoConnection';
import React from 'react';
import { identity } from 'utils/identity';

export const FrozenAccount = () => {
  const fullName = `${identity.firstname} ${identity.lastname}`;
  return (
    <>
      <PageHeader title='Mine abonnementer' subheader={fullName} />
      <Container maxWidth='md'>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row-reverse' },
            }}>
            <Box
              sx={{
                display: 'flex',
                width: { xs: 240, sm: '50%' },
                margin: 'auto',
                ['& > svg']: {
                  height: { sm: 240 },
                  width: { sm: 300 },
                },
              }}>
              <NoConnectionSvg />
            </Box>
            <Box sx={{ mr: { lg: 4 } }}>
              <Typography variant='h3'>
                Vi opdaterer din selvbetjening - derfor er her lidt tomt
              </Typography>
              <Typography variant='regular16'>
                Vi arbejder på at gøre din selvbetjening endnu bedre. Mens vi pusler i
                maskinrummet, vil nogle af funktionerne i selvbetjeningen være låst. Men vend
                endelig tilbage om et par dage - så kan du bruge alle funktioner igen.
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};
