import { get } from 'shared/utils/api';
import { ColumbusSubscriptionRawDataInterface } from './useMyColumbusSubscriptionsTypes';

export const fetchColumbusSubscriptionDetails = (
  subscription: ColumbusSubscriptionRawDataInterface,
): Promise<ColumbusSubscriptionRawDataInterface> => {
  // Get the api base
  const requestUrl = `/yousee/selfcare/products/${subscription.subscriptionId}/menu-details`;
  const columbusSubscription: Promise<ColumbusSubscriptionRawDataInterface> = get({
    url: requestUrl,
    baseHost: 'ODIN',
  });

  const promise = new Promise<ColumbusSubscriptionRawDataInterface>((resolve, reject) => {
    columbusSubscription
      .then((response) => {
        if (response) {
          // keep the subscriptionId that includes the subscriptionId and productCode
          response = { ...response, subscriptionId: subscription.subscriptionId };
          resolve(response);
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });
  return promise;
};
