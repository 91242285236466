import { createNanoEvents } from 'nanoevents';
import { getFromLocalStorage, removeFromLocalStorage, setInLocalStorage } from 'utils/storage';
import { getExpirationDate, hasExpired } from './dates';

interface Events {
  update: (key: string, value: any) => void;
}

const events = createNanoEvents<Events>();

const set = (key: string, data: any, expireInHours?: number) => {
  events.emit('update', key, data);
  setInLocalStorage(key, {
    data,
    expirationDate: expireInHours ? getExpirationDate(expireInHours) : null,
  });
};

const get = (key: string) => {
  try {
    const parsed: { data: any; expirationDate: Date } = getFromLocalStorage(key);
    if (!parsed) {
      return null;
    }
    if (hasExpired(parsed.expirationDate)) {
      return removeFromLocalStorage(key);
    }
    return parsed.data;
  } catch (error) {
    return null;
  }
};

const remove = (key: string) => {
  events.emit('update', key, null);
  removeFromLocalStorage(key);
};
export const local = { get, set, remove, events };
