import { getIconName } from 'hooks/useMyColumbusSubscriptions/useMyColumbusSubscriptionsUtils';
import React, { FC } from 'react';
type LeftNavSubscriptionIconProps = {
  iconName: string;
};
export const LeftNavSubscriptionIcon: FC<LeftNavSubscriptionIconProps> = ({ iconName }) => {
  return (
    <figure className='navpanel__item-icon' aria-hidden='true'>
      <svg className='navpanel__item-icon-svg'>
        <use
          xlinkHref={`#navigation--mityousee--${getIconName(
            iconName ? iconName : 'mobile',
          )}`}></use>
      </svg>
    </figure>
  );
};
