import React from 'react';

export const Icons = () => {
  return (
    <div style={{ display: 'none' }}>
      <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
        <symbol
          viewBox='0 0 21 24'
          id='navigation--mityousee--benefits'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd' transform='translate(-4)'>
            <path opacity='.2' d='M0 0h24v24H0z' />
            <path
              stroke='#484848'
              strokeWidth='2'
              d='M19 15.052V22a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h7.963'
            />
            <circle cx='12.32' cy='19.32' r='1.32' fill='#484848' />
            <path d='M19 2.5v9M23.5 7h-9' stroke='#484848' strokeLinecap='round' strokeWidth='2' />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 18 22'
          id='navigation--mityousee--bills'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            fill='none'
            stroke='#484848'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M11 1v6h6v13a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h9zM6 12h6-6zm0 4h6-6zm5-15l6 6'
          />
        </symbol>
        <symbol
          viewBox='0 0 22 17'
          id='navigation--mityousee--broadband'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd'>
            <path
              fill='#484848'
              d='M11 16.172a1.724 1.724 0 1 1 0-3.447 1.724 1.724 0 0 1 0 3.447'
            />
            <path
              stroke='#484848'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1.7'
              d='M3.759 8.586c1.746-2.11 4.344-3.448 7.245-3.448 2.897 0 5.491 1.334 7.237 3.438M1 5.138C3.494 2.592 7.051 1 11 1s7.506 1.592 10 4.138m-6.207 6.207c-.97-1.26-2.461-2.07-4.137-2.07-1.677 0-3.17.81-4.139 2.07'
            />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 22 15'
          id='navigation--mityousee--creditcard'
          xmlns='http://www.w3.org/2000/svg'>
          <g
            fill='none'
            fillRule='evenodd'
            stroke='#484848'
            strokeWidth='2'
            transform='translate(1 1)'>
            <rect width='20' height='13' rx='1' />
            <path d='M1 4h18v2H1z' />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 32 32'
          id='navigation--mityousee--error'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd'>
            <circle cx='16' cy='16' r='16' fill='#FAE8E8' />
            <path
              fill='#D31A20'
              fillRule='nonzero'
              d='M15 10.5a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0v-6z'
            />
            <circle cx='16' cy='21' r='1.5' fill='#D31A20' />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 21 20'
          id='navigation--mityousee--logout'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd' stroke='#484848' strokeLinecap='round' strokeWidth='2'>
            <path d='M12 5V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-3M7 10h12M20 10l-4 5M20 10l-4-5' />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 24 16'
          id='navigation--mityousee--mail'
          xmlns='http://www.w3.org/2000/svg'>
          <g
            fill='none'
            fillRule='evenodd'
            stroke='#484848'
            strokeWidth='2'
            transform='translate(0 1)'>
            <rect width='22' height='14' x='1' rx='2' />
            <path d='M.8.6l10.038 7.17a2 2 0 0 0 2.324 0L23.2.6' />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 24 24'
          id='navigation--mityousee--mail-settings'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd'>
            <path opacity='.2' d='M0 0h24v24H0z' />
            <path
              d='M2 3h20a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z'
              stroke='#484848'
              strokeWidth='2'
            />
            <path
              d='M.8 3.6l10.038 7.17a2 2 0 0 0 2.325 0L23.201 3.6'
              stroke='#484848'
              strokeWidth='2'
            />
            <path fill='#fff' fillRule='nonzero' d='M22 12h2v12h-2z' />
            <path fill='#fff' fillRule='nonzero' d='M11 16h12v2H11z' />
            <path
              d='M14.058 21.947a.452.452 0 0 1-.604-.173l-.892-1.548a.454.454 0 0 1 .152-.61l.619-.38a.868.868 0 0 0 .372-.666v-1.14a.868.868 0 0 0-.373-.667l-.619-.379a.453.453 0 0 1-.152-.61l.893-1.548a.453.453 0 0 1 .604-.173l.638.347c.212.115.554.11.762-.01l.988-.57a.867.867 0 0 0 .39-.656l.02-.725a.453.453 0 0 1 .451-.438L19.093 12c.244 0 .446.196.452.437l.02.726a.869.869 0 0 0 .39.656l.987.57c.209.121.55.126.763.01l.637-.346a.452.452 0 0 1 .605.173l.892 1.549a.454.454 0 0 1-.153.61l-.618.379a.868.868 0 0 0-.373.666v1.14c.001.242.168.54.374.667l.618.379a.453.453 0 0 1 .153.61l-.893 1.548a.453.453 0 0 1-.605.173l-.637-.346a.868.868 0 0 0-.763.01l-.988.57a.867.867 0 0 0-.39.655l-.019.725a.453.453 0 0 1-.452.438L17.307 24a.453.453 0 0 1-.452-.437l-.019-.726a.869.869 0 0 0-.39-.656l-.987-.57a.867.867 0 0 0-.763-.01l-.638.346z'
              fill='#484848'
            />
            <circle cx='18.2' cy='18' r='2' fill='#fff' fillRule='nonzero' />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 16 24'
          id='navigation--mityousee--mobile'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd' transform='translate(1 1)'>
            <rect width='14' height='22' stroke='#484848' strokeWidth='2' rx='1' />
            <circle cx='7.04' cy='18.04' r='1.32' fill='#484848' />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 28 27'
          id='navigation--mityousee--mobilebroadband'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd' transform='rotate(-45 9.89 5.51)'>
            <rect width='10' height='14' x='2' y='12' stroke='#484848' strokeWidth='2' rx='2' />
            <path fill='#484848' d='M9.707 11.207a2.5 2.5 0 1 0-5 0c0 1.38 5 1.38 5 0z' />
            <path
              stroke='#484848'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1.7'
              d='M0 3.586C1.747 1.476 4.345.138 7.246.138c2.896 0 5.49 1.334 7.237 3.438m-3.207 2.769c-.97-1.26-2.461-2.07-4.138-2.07S3.97 5.086 3 6.346'
            />
            <path
              stroke='#484848'
              strokeWidth='2'
              d='M9.5 26.5h-5v2a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-2z'
            />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 24 24'
          id='navigation--mityousee--notification'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M18.2 17.775H6v-5.608a6.003 6.003 0 0 1 5.997-5.997 6.209 6.209 0 0 1 6.202 6.203v5.402zM12.1 2a.795.795 0 0 1 0 1.587.794.794 0 0 1 0-1.587zm0 20c-1.395 0-2.54-.945-2.644-2.14l5.285.023C14.627 21.067 13.485 22 12.1 22zm2.193-17.495c.371-.474.6-1.063.6-1.71a2.794 2.794 0 0 0-5.587 0c0 .627.215 1.202.567 1.67C6.49 5.395 4 8.486 4 12.166v6.755c0 .472.382.853.852.853h2.514l.092.175C7.605 22.206 9.631 24 12.1 24c2.472 0 4.5-1.797 4.644-4.056l.087-.169h2.516a.852.852 0 0 0 .853-.853v-6.549c0-3.715-2.504-6.868-5.906-7.868z'
            fill='#484848'
          />
        </symbol>
        <symbol
          viewBox='0 0 20 20'
          id='navigation--mityousee--overview'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            fill='none'
            stroke='#484848'
            strokeWidth='2'
            d='M2 1a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm11 0a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-5zM2 12a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H2zm11 0a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-5z'
          />
        </symbol>
        <symbol
          viewBox='0 0 24 24'
          id='navigation--mityousee--phone'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            fill='none'
            stroke='#484848'
            strokeWidth='2'
            d='M22.448 16.996l-2.63-2.626a1.843 1.843 0 0 0-2.63 0l-2.104 2.101c-3.051-1.47-6.102-4.518-7.47-7.565l2-2.101c.736-.736.736-1.891 0-2.522l-2.63-2.731a1.843 1.843 0 0 0-2.63 0l-2.63 2.731C-2.8 8.801 15.188 26.873 19.817 22.25l2.63-2.627a1.838 1.838 0 0 0 0-2.627z'
          />
        </symbol>
        <symbol
          viewBox='0 0 20 22'
          id='navigation--mityousee--profile'
          xmlns='http://www.w3.org/2000/svg'>
          <defs>
            <path
              id='na'
              d='M1.94 20.875c0 .552-.435 1-.97 1-.536 0-.97-.448-.97-1v-.734C0 16.736 2.946 14 6.504 14h6.992C17.054 14 20 16.736 20 20.14v.735c0 .552-.434 1-.97 1-.535 0-.97-.448-.97-1v-.734c0-2.25-2.035-4.141-4.564-4.141H6.504c-2.53 0-4.564 1.89-4.564 4.14v.735zM16 6A6 6 0 1 1 4 6a6 6 0 0 1 12 0zm-2.047 0A3.956 3.956 0 0 0 10 2.042 3.955 3.955 0 0 0 6.047 6 3.955 3.955 0 0 0 10 9.958 3.955 3.955 0 0 0 13.953 6z'
            />
          </defs>
          <use fill='#484848' fillRule='nonzero' xlinkHref='#na' />
        </symbol>
        <symbol
          viewBox='0 0 22 24'
          id='navigation--mityousee--settings'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd' transform='translate(1 1)'>
            <path
              stroke='#484848'
              strokeWidth='2'
              d='M2.733 17.45a.792.792 0 0 1-1.06-.303L.108 14.431a.796.796 0 0 1 .268-1.071l1.085-.665c.36-.222.653-.747.653-1.168l-.001-2.002c0-.424-.294-.947-.654-1.168L.374 7.69a.794.794 0 0 1-.268-1.07l1.567-2.716a.795.795 0 0 1 1.06-.303l1.119.608c.372.202.973.193 1.338-.017l1.733-1c.367-.212.673-.728.684-1.15L7.64.768a.794.794 0 0 1 .793-.767L11.567 0c.427 0 .782.344.793.767l.033 1.273c.01.424.319.94.684 1.151l1.733 1.001c.367.212.967.22 1.338.018l1.12-.608a.792.792 0 0 1 1.06.303l1.564 2.717c.213.37.093.85-.268 1.07l-1.085.666c-.36.221-.653.746-.653 1.168l.001 2.002c0 .424.294.947.654 1.168l1.085.665a.794.794 0 0 1 .268 1.07l-1.567 2.716a.795.795 0 0 1-1.06.304l-1.119-.608c-.372-.202-.973-.194-1.338.017l-1.733 1c-.367.212-.673.727-.684 1.15l-.033 1.273a.794.794 0 0 1-.793.768l-3.134.002a.795.795 0 0 1-.793-.767l-.033-1.274c-.01-.423-.319-.94-.684-1.15L5.19 16.86c-.367-.212-.967-.22-1.338-.017l-1.12.608z'
            />
            <circle cx='10' cy='10.5' r='2.5' fill='#484848' />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 24 19'
          id='navigation--mityousee--tv'
          xmlns='http://www.w3.org/2000/svg'>
          <g
            fill='none'
            fillRule='evenodd'
            stroke='#484848'
            strokeWidth='2'
            transform='translate(1 1)'>
            <rect width='22' height='13' rx='2' />
            <path strokeLinecap='round' d='M6 17h10' />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 26 26'
          id='navigation--mityousee--youseemore'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd' transform='translate(1 1)'>
            <path opacity='.2' d='M6 6h12v12H6z' />
            <circle cx='12' cy='12' r='12' stroke='#484848' strokeWidth='2' />
            <path
              stroke='#484848'
              strokeLinecap='round'
              strokeWidth='2'
              d='M12 7.5v9m4.5-4.5h-9'
            />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 32 32'
          id='navigation--shortcuts--contact'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            fill='none'
            stroke='#484848'
            strokeWidth='2'
            d='M29.297 22.36l-3.347-3.344a2.346 2.346 0 0 0-3.347 0l-2.678 2.675c-3.883-1.873-7.766-5.75-9.507-9.628l2.544-2.675c.938-.936.938-2.407 0-3.21L9.615 2.703a2.346 2.346 0 0 0-3.347 0L2.92 6.179c-5.758 5.75 17.138 28.75 23.029 22.866l3.347-3.343a2.339 2.339 0 0 0 0-3.343z'
          />
        </symbol>
        <symbol
          viewBox='0 0 32 32'
          id='navigation--shortcuts--driftsinformation'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd'>
            <path
              stroke='#484848'
              strokeWidth='2'
              d='M15.126 3.753L4.142 23.514A1 1 0 0 0 5.016 25h21.968a1 1 0 0 0 .874-1.486L16.874 3.753a1 1 0 0 0-1.748 0z'
            />
            <path
              stroke='#484848'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M16 11v5'
            />
            <path
              fill='#484848'
              fillRule='nonzero'
              d='M17.5 20a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0'
            />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 32 32'
          id='navigation--shortcuts--find-store'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd'>
            <path
              stroke='#484848'
              strokeWidth='2'
              d='M24 11.302C24 15.886 16 28 16 28S8 15.886 8 11.302C8 6.717 11.582 3 16 3s8 3.717 8 8.302z'
            />
            <path
              fill='#484848'
              fillRule='nonzero'
              d='M18.5 11a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z'
            />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 32 32'
          id='navigation--shortcuts--fogh'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd' stroke='#484848' strokeWidth='2'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M25 8v20a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8'
            />
            <path strokeLinecap='round' d='M3.5 10l11.331-8.159a2 2 0 0 1 2.338 0L28.5 10' />
            <path d='M14 29h4v-7h-4v7zm-2-17v-2 2zm4 0v-2 2zm4 0v-2 2zm-8 6v-2 2zm4 0v-2 2zm4 0v-2 2z' />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 32 32'
          id='navigation--shortcuts--forum'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd'>
            <path
              stroke='#484848'
              strokeWidth='2'
              d='M20.906 4.533c-5.3 0-9.596 3.177-9.596 7.096 0 4.662 5.295 7.47 10.595 7.47l4.284 3.197.573-4.8c2.67-1.26 3.741-3.35 3.741-5.867 0-3.919-4.297-7.096-9.597-7.096'
            />
            <path
              stroke='#484848'
              strokeWidth='2'
              d='M9.558 12.56c-4.095.39-7.284 3.29-7.284 6.54 0 2.248 1.248 4.179 3.83 5.305l-.814 3.16 3.518-2.022c5.915 1.033 10.232-2.194 11.125-4.518'
            />
            <path
              fill='#484848'
              fillRule='nonzero'
              d='M18.008 12.027c0 .605-.503 1.095-1.123 1.095s-1.123-.49-1.123-1.095c0-.604.502-1.095 1.123-1.095.62 0 1.123.49 1.123 1.095m3.839 0c0 .605-.503 1.095-1.123 1.095s-1.123-.49-1.123-1.095c0-.604.502-1.095 1.123-1.095.62 0 1.123.49 1.123 1.095m3.916 0c0 .605-.503 1.095-1.123 1.095s-1.124-.49-1.124-1.095c0-.604.503-1.095 1.124-1.095.62 0 1.123.49 1.123 1.095'
            />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 32 32'
          id='navigation--shortcuts--music'
          xmlns='http://www.w3.org/2000/svg'>
          <g
            fill='none'
            fillRule='evenodd'
            stroke='#484848'
            strokeWidth='2'
            transform='translate(3 3)'>
            <path d='M7 20.78V3.6a1 1 0 0 1 .804-.98l13-2.6A1 1 0 0 1 22 1v16.78m-15-8l15-3' />
            <ellipse cx='3.5' cy='21.28' rx='3.5' ry='2.5' />
            <ellipse cx='18.5' cy='18.28' rx='3.5' ry='2.5' />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 32 32'
          id='navigation--shortcuts--speedtest'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd'>
            <path fill='#484848' fillRule='nonzero' d='M18 16.992a2 2 0 1 1-4 0 2 2 0 0 1 4 0' />
            <path
              stroke='#484848'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M20.5 12.492l-3.79 3.79'
            />
            <path
              stroke='#484848'
              strokeWidth='2'
              d='M16 5.992c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11'
            />
            <path
              stroke='#484848'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M16 1.492v4m2-4.5h-4m12 6l-2.121 2.122M28 7.992l-2.828-2.829'
            />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 32 32'
          id='navigation--shortcuts--tv-movie'
          xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd' transform='translate(1 8)'>
            <rect width='30' height='18' stroke='#484848' strokeWidth='2' rx='2' />
            <rect width='13' height='1' x='8.5' y='21.5' stroke='#484848' rx='.5' />
            <path fill='#484848' fillRule='nonzero' d='M11 5l8 4-8 4z' />
          </g>
        </symbol>
        <symbol
          viewBox='0 0 32 32'
          id='navigation--shortcuts--webmail'
          xmlns='http://www.w3.org/2000/svg'>
          <g
            fill='none'
            fillRule='evenodd'
            stroke='#484848'
            strokeWidth='2'
            transform='translate(2 8)'>
            <rect width='28' height='17' rx='2' />
            <path d='M0 1l14 10L28 1' />
          </g>
        </symbol>
      </svg>
    </div>
  );
};
