const userAgents = ['youseeSelfcareApp', 'netcrackerApp'] as const;
export type UserAgent = (typeof userAgents)[number];

/**
 * Returns true if the user agent is one of the provided agents, false otherwise.
 */
export const isUserAgent = (agents: UserAgent[]) =>
  agents.some((userAgent) => window.navigator.userAgent.includes(userAgent));

export const isMobileApp = isUserAgent(['youseeSelfcareApp']);
export const isNetcrackerApp = isUserAgent(['netcrackerApp']);
