import { Box, Button, Container } from '@mui/material';
import { Receipt } from '@youseedk/dna-react';
import React from 'react';

import { removeAuthCookies } from 'components/navigation/Components/LeftNav/LeftNav';
import { getConfig } from 'shared/config';
import { getDelegatedLoginURL } from 'shared/utils/api/request';
import { featLoginWithCiamPing } from 'utils/feature-toggle/feat-login-with-ciam-ping';

export const NotLoggedIn = () => {
  let returnUrl = window.location.href;
  if (getConfig().redirectIfNotLoggedIn) {
    // check if we have a hash in the url, if so, we need to encode it to survive the redirect
    const hash = window.location.hash;

    if (hash) {
      returnUrl = `${returnUrl.split('#')[0]}%23${hash.split('#')[1]}`;
    }

    window.location.href = `${getDelegatedLoginURL()}?ReturnUrl=${returnUrl}`;
  }

  return (
    featLoginWithCiamPing() &&
    process.env.NODE_ENV !== 'production' && (
      <>
        <Receipt icon='lock' title='Ups, du er ikke logget ind' />
        <Container maxWidth='md'>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='default'
              size='small'
              href={`${getDelegatedLoginURL()}?ReturnUrl=${returnUrl}`}
              onClick={removeAuthCookies}>
              Log ind (CIAM Ping)
            </Button>
          </Box>
        </Container>
      </>
    )
  );
};
