import { NetCrackerSubscriptionType } from 'api/netCrackerAPI/netCrackerApiTypes';
import { ColumbusSubscriptionType } from 'hooks/useMyColumbusSubscriptions/useMyColumbusSubscriptionsTypes';
import React, { FC } from 'react';

type SubscriptionNavItemDetailsProps = {
  subscription: NetCrackerSubscriptionType | ColumbusSubscriptionType;
};

export const SubscriptionNavItemDetails: FC<SubscriptionNavItemDetailsProps> = ({
  subscription,
}) => {
  return (
    <span className='navpanel__item-subtext' data-hj-suppress=''>
      {['Fixednet', 'Mobile', 'MobileBroadband'].indexOf(subscription.productType) !== -1 &&
        subscription.getPhoneNumber()}
      {['Broadband', 'Tv'].indexOf(subscription.productType) !== -1 && subscription.getAddress()}
    </span>
  );
};
