import { Box, Button } from '@mui/material';
import { colors } from '@nuuday/yousee-dna/generated/colors';
import BurgerMenu from '@nuuday/yousee-dna/generated/icons/general/BurgerMenu';
import Close from '@nuuday/yousee-dna/generated/icons/general/Close';
import { events } from 'components/navigation/Utils/events';
import React, { useEffect, useState } from 'react';
import { useScreenSize } from 'shared/utils/screenSize';

export function LeftNavigationButtonBar() {
  const isDesktopNavigation = useScreenSize('desktopNavigation');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    events.on('open', () => {
      setOpen(true);
    });
    events.on('close', () => {
      setOpen(false);
    });
  }, []);

  return (
    <Box
      sx={{
        display: isDesktopNavigation ? 'none' : '',
        height: '40px',
        background: colors.White,
        zIndex: 1000,
      }}>
      <Box
        sx={{
          position: 'fixed',
          top: 'var(--nd-total-header-height)',
          left: 0,
          width: '100%',
          height: '40px',
          background: colors.White,
        }}>
        <Button
          id='navheaderButtonSelfcare'
          data-nav-id='nav-selfcare'
          variant='tertiary'
          size='small'
          sx={{ 'ml': 4, '&:hover': { color: colors.Text } }}
          startIcon={open ? <Close /> : <BurgerMenu />}>
          Mit YouSee menu
        </Button>
      </Box>
    </Box>
  );
}
