import { useQuery } from '@tanstack/react-query';
import {
  getListCustomersByIndividualId,
  getProductListCustomersId,
} from 'api/netCrackerAPI/netCrackerApi';
import { useEffect, useState } from 'react';
import { featNetcrackerLive } from 'utils/feature-toggle/feat-netcracker-live';
import { getIndividualId, hasDualStackProducts } from 'utils/misc';

const useMyNetcrackerSubscriptions = () => {
  const isStackDual = hasDualStackProducts() && featNetcrackerLive();
  const individualId = getIndividualId() || 'test';

  const {
    data: customerId,
    isError: customerQueryError,
    isLoading: isCustomerQueryLoading,
  } = useQuery({
    queryKey: ['netCrackerCustomer', isStackDual, individualId],
    queryFn: getListCustomersByIndividualId,
    enabled: isStackDual,
    staleTime: 1000 * 60, // one minute
  });

  const {
    data: subscriptions,
    isError: productsQueryError,
    isLoading: isProductsQueryLoading,
  } = useQuery({
    queryKey: ['netCrackerProducts', customerId],
    queryFn: () => getProductListCustomersId(customerId),
    enabled: !!customerId,
    staleTime: 1000 * 60, // one minute
  });

  const [netCrackerProductListErrorMessage, setNetCrackerProductListErrorMessage] =
    useState<string>('');

  useEffect(() => {
    setNetCrackerProductListErrorMessage(
      customerQueryError || productsQueryError
        ? 'Vi kunne ikke hente alle dine abonnementer - vi beklager'
        : '',
    );
  }, [customerQueryError, productsQueryError]);

  return {
    netCrackerSubscriptions: subscriptions ? subscriptions : [],
    netCrackerProductListErrorMessage,
    isLoadingNetCracker: isCustomerQueryLoading || (isProductsQueryLoading && !customerQueryError),
  };
};
export default useMyNetcrackerSubscriptions;
