import React from 'react';

export const NoConnectionSvg = () => (
  <svg
    width='358'
    height='281'
    viewBox='0 0 358 281'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_202:1582)'>
      <rect x='12.3311' y='134.483' width='340.288' height='6.80227' rx='3.40113' fill='#EEEEEE' />
      <rect x='304.313' y='139.085' width='3.70488' height='13.4302' fill='#EEEEEE' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M306.166 273.912C304.247 273.912 302.692 272.357 302.692 270.439V150.052C302.692 148.133 304.247 146.578 306.166 146.578C308.084 146.578 309.639 148.133 309.639 150.052V270.439C309.639 272.357 308.084 273.912 306.166 273.912Z'
        fill='#EEEEEE'
      />
      <rect
        width='3.70488'
        height='13.4302'
        transform='matrix(-1 0 0 1 63.6055 139.085)'
        fill='#EEEEEE'
      />
      <rect
        width='126.846'
        height='6.94665'
        rx='3.47333'
        transform='matrix(0 -1 -1 0 65.2266 273.424)'
        fill='#EEEEEE'
      />
      <mask
        id='mask0_202:1582'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='190'
        y='130'
        width='76'
        height='5'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M190.883 130.238C190.663 130.238 190.485 130.415 190.485 130.634V132.322C190.485 133.473 191.419 134.407 192.57 134.407H263.449C264.6 134.407 265.533 133.473 265.533 132.322V130.634C265.533 130.415 265.356 130.238 265.137 130.238H190.883Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_202:1582)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M189.492 135.4H266.525V129.245H189.492V135.4Z'
          fill='#9B9B9B'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M200.574 82.4707C199.697 82.4707 198.903 83.1818 198.802 84.0589L193.591 128.947C193.49 129.824 194.118 130.535 194.995 130.535H261.024C261.902 130.535 262.695 129.824 262.797 128.947L268.007 84.0589C268.109 83.1818 267.481 82.4707 266.603 82.4707H200.574Z'
        fill='#383838'
      />
      <mask
        id='mask1_202:1582'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='193'
        y='82'
        width='76'
        height='49'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M200.574 82.4707C199.697 82.4707 198.903 83.1818 198.802 84.0589L193.591 128.947C193.49 129.824 194.118 130.535 194.995 130.535H261.024C261.902 130.535 262.695 129.824 262.797 128.947L268.007 84.0589C268.109 83.1818 267.481 82.4707 266.603 82.4707H200.574Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_202:1582)'></g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M196.081 127.824H260.047L265.091 85.5891H201.126L196.081 127.824Z'
        fill='white'
      />
      <mask
        id='mask2_202:1582'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='196'
        y='85'
        width='70'
        height='43'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M196.081 127.824H260.047L265.091 85.5891H201.126L196.081 127.824Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask2_202:1582)'>
        <path
          d='M255.464 89H209.066C207.05 89 205.349 90.4998 205.097 92.4997L201.567 120.5C201.266 122.888 203.128 125 205.536 125H251.934C253.95 125 255.651 123.5 255.903 121.5L259.433 93.5003C259.734 91.1115 257.872 89 255.464 89Z'
          fill='#F0F0F0'
        />
        <rect x='220' y='91' width='35' height='2' rx='1' fill='#A2A2A2' />
        <circle cx='209' cy='92' r='1' fill='#A2A2A2' />
        <circle cx='213' cy='92' r='1' fill='#A2A2A2' />
        <circle cx='217' cy='92' r='1' fill='#A2A2A2' />
        <path
          d='M223.319 102.846L236.681 117.213'
          stroke='#A2A2A2'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M237.223 103.449L222.777 116.609'
          stroke='#A2A2A2'
          strokeWidth='3'
          strokeLinecap='round'
        />
      </g>
      <path
        d='M157.798 252.363C157.386 251.863 157.03 251.398 156.77 251.049C156.843 250.963 156.922 250.869 157.007 250.768C157.464 250.227 158.086 249.483 158.753 248.673C160.072 247.071 161.608 245.153 162.335 244.05C163.04 242.98 163.963 241.464 164.704 240.226C165.053 239.643 165.363 239.118 165.594 238.725L173.974 241.784C173.623 242.356 173.155 243.117 172.64 243.95C171.546 245.717 170.25 247.792 169.415 249.059C168.549 250.375 167.258 252.623 166.197 254.516C165.663 255.468 165.183 256.339 164.836 256.973C164.787 257.061 164.741 257.145 164.698 257.224C164.442 257.119 164.138 256.99 163.804 256.842C162.816 256.405 161.61 255.815 160.648 255.181C159.753 254.592 158.686 253.443 157.798 252.363Z'
        fill='white'
        stroke='#484848'
        strokeWidth='2'
      />
      <path
        d='M215.718 263.892C215.465 263.82 215.239 263.754 215.046 263.697C215.06 263.404 215.073 263.04 215.079 262.622C215.095 261.48 215.058 259.915 214.842 258.237C214.634 256.614 214.419 255.045 214.256 253.883C214.212 253.565 214.171 253.278 214.136 253.029L222.686 252.438C222.691 252.667 222.7 252.926 222.714 253.208C222.769 254.32 222.907 255.823 223.245 257.353C223.591 258.923 224.105 259.993 224.564 260.692C224.793 261.041 225.005 261.293 225.172 261.467C225.214 261.511 225.253 261.55 225.289 261.584C225.294 261.593 225.299 261.603 225.303 261.613C225.364 261.74 225.45 261.924 225.544 262.148C225.735 262.6 225.952 263.188 226.085 263.777C226.223 264.39 226.241 264.88 226.151 265.198C226.111 265.34 226.057 265.421 226.003 265.473C225.952 265.523 225.854 265.591 225.649 265.635C225.115 265.748 224.167 265.716 222.926 265.54C221.722 265.37 220.364 265.086 219.077 264.777C217.792 264.47 216.595 264.143 215.718 263.892Z'
        fill='white'
        stroke='#484848'
        strokeWidth='2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M148.337 191.408C157.951 191.408 169.57 161 169.57 161L160 146L179.327 92.7817C179.327 92.7817 181.92 79.0065 169.57 76.7106C157.22 74.4147 142.019 73.7785 142.019 73.7785C142.019 73.7785 125.965 72.6306 127.112 88.1901C127.112 98.6688 134.215 113.018 134.194 132.322C134.173 151.626 127.109 156.472 127.113 171.953C127.116 187.434 138.723 191.408 148.337 191.408Z'
        fill='#1FAB2E'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M187.499 177.248L163.373 241.551L171.375 248.2L209.222 187.987L187.499 177.248Z'
        fill='#77ACC7'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M225.5 180.5L224 253.5L212.919 255.04C212.919 255.04 208.5 234 206.5 220.5C204.5 207 206.5 180.5 206.5 180.5L225.5 180.5Z'
        fill='#77ACC7'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M176.225 134.515L187.412 128.187L175.166 92.0772L161.1 97.1341L176.225 134.515Z'
        fill='white'
        stroke='#484848'
        strokeWidth='2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M216.464 132.746C222.133 132.753 225.275 129.255 225.275 129.255L234.92 129.198C235.886 129.196 236.125 129.194 236.271 128.834C236.417 128.474 236.277 128.155 235.502 127.842C234.727 127.529 223.516 122.91 223.498 122.878C222.969 122.665 222.163 122.671 221.725 122.891C220.059 123.726 215.711 126.968 215.711 126.968L186.969 126.014C186.969 126.014 180.872 123.954 178.103 126.082C172.07 130.721 176.206 138.787 183.291 138.734C190.375 138.68 216.464 132.746 216.464 132.746Z'
        fill='white'
        stroke='#484848'
        strokeWidth='2'
      />
      <path
        d='M161.511 36.5C154.938 36.5 150.084 41.7462 150.084 51.5405L147 76.8766C147 76.8766 150.084 81.3024 154.91 81.3024C159.736 81.3024 161.511 76.8766 161.511 76.8766L159.86 69.1315C168.738 69.1315 170.862 59.7267 170.862 59.7267H174.162L171.962 54.1944C171.962 42.0235 169.288 36.5 161.511 36.5Z'
        fill='white'
      />
      <path
        d='M170.862 59.7267C170.862 59.7267 168.738 69.1315 159.86 69.1315L161.511 76.8766C161.511 76.8766 159.736 81.3024 154.91 81.3024C150.084 81.3024 147 76.8766 147 76.8766L150.084 51.5405C150.084 41.7462 154.938 36.5 161.511 36.5C169.288 36.5 171.962 42.0235 171.962 54.1944L174.162 59.7267M170.862 59.7267H174.162M170.862 59.7267C170.862 59.7267 172.933 59.7267 174.162 59.7267'
        stroke='#484848'
        strokeWidth='2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M154.918 32C134.205 32 137.516 53.3281 137.516 53.3281L147.5 67C147.5 67 157.261 59.5402 159.559 53.3281C160.619 50.46 162.718 45.125 162.718 45.125L172.321 48.4062C172.321 48.4062 172.754 32 154.918 32Z'
        fill='#063623'
      />
      <ellipse cx='157.773' cy='53.0593' rx='4.27422' ry='5.04712' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M178.754 84.1724L183.346 105.983L160.961 111.723L158.314 95.1823L178.754 84.1724Z'
        fill='#1FAB2E'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M225.144 184.514C223.376 192.01 206.697 191.414 206.697 191.414H141.446C141.446 191.414 124.801 170.54 141.446 168.244C158.091 165.948 168.423 158.789 168.423 158.789C168.423 158.789 205.272 165.936 215.7 168.244C223.754 170.026 226.913 177.017 225.144 184.514Z'
        fill='#77ACC7'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M143.916 189.805L141.146 188.851L136.773 201.549L139.544 202.503L143.916 189.805Z'
        fill='#063623'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M114.184 272.275C112.802 271.588 112.136 269.989 112.62 268.523L135.857 198.24C136.45 196.448 138.492 195.596 140.183 196.437C141.565 197.124 142.232 198.722 141.747 200.188L118.511 270.471C117.918 272.263 115.875 273.115 114.184 272.275Z'
        fill='#003025'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M175.302 189.804L178.073 188.85L182.445 201.549L179.675 202.503L175.302 189.804Z'
        fill='#063623'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M205.034 272.274C206.416 271.587 207.083 269.988 206.598 268.523L183.362 198.24C182.769 196.448 180.726 195.596 179.035 196.436C177.653 197.123 176.987 198.722 177.471 200.187L200.708 270.47C201.301 272.263 203.344 273.115 205.034 272.274Z'
        fill='#003025'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M111.466 103.245C111.466 103.245 117.032 101.102 123.668 101.102C130.305 101.102 146.328 111.104 149.31 143.783C152.291 176.463 154.306 178.804 178.766 179.281C203.226 179.759 208.008 191.585 208.008 191.585C208.008 191.585 195.138 191.541 181.968 191.541C168.798 191.541 163.02 191.541 140.346 191.541C117.673 191.541 115.545 171.983 117.032 145.656C118.518 119.328 111.466 103.245 111.466 103.245Z'
        fill='#191919'
      />
      <rect x='7' y='8' width='59.8184' height='74.773' stroke='#EEEEEE' strokeWidth='3' />
      <path
        d='M31.7793 60.0962C31.8303 57.0378 32.1743 54.6219 32.8113 52.8485C33.4484 51.0751 34.748 49.109 36.7102 46.9501L41.7176 41.7456C43.8582 39.3039 44.9285 36.6824 44.9285 33.881C44.9285 31.1824 44.2277 29.0749 42.8261 27.5585C41.4246 26.0164 39.3859 25.2454 36.7102 25.2454C34.111 25.2454 32.0214 25.9393 30.4414 27.3272C28.8615 28.7151 28.0715 30.5784 28.0715 32.9172H21C21.051 28.7536 22.5162 25.3996 25.3958 22.8552C28.3009 20.2851 32.0723 19 36.7102 19C41.5265 19 45.2725 20.3108 47.9482 22.9323C50.6494 25.5281 52 29.1006 52 33.6497C52 38.1474 49.9359 42.5809 45.8076 46.9501L41.6412 51.1137C39.7809 53.1954 38.8508 56.1896 38.8508 60.0962H31.7793ZM31.4735 67.9906C31.4735 66.8341 31.8175 65.8703 32.5055 65.0992C33.2191 64.3025 34.2639 63.9041 35.64 63.9041C37.016 63.9041 38.0608 64.3025 38.7744 65.0992C39.4879 65.8703 39.8446 66.8341 39.8446 67.9906C39.8446 69.1472 39.4879 70.111 38.7744 70.882C38.0608 71.6273 37.016 72 35.64 72C34.2639 72 33.2191 71.6273 32.5055 70.882C31.8175 70.111 31.4735 69.1472 31.4735 67.9906Z'
        fill='#EEEEEE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M144.393 98.3346C143.616 86.5028 140.302 80.2823 137.833 73.012C137.33 71.5294 133.405 63.0252 140.666 55.368C143.068 51.3564 138.081 49.5384 138.081 49.5384C138.081 49.5384 116.823 60.2305 121.637 86.813C124.292 105.122 112.845 115.879 122.865 122.803C133.163 129.916 145.867 120.743 144.393 98.3346Z'
        fill='#063623'
      />
      <path
        d='M213.478 273.446L213.648 260.426L224.527 259.296L230.206 264.032L242.671 267.62C243.71 267.919 243.601 269.424 242.53 269.571L233.47 270.816L213.478 273.446Z'
        fill='#063623'
      />
      <path
        d='M150.233 255.088L159.493 245.933L168.035 252.765L168.756 260.124L175.116 271.429C175.646 272.371 174.512 273.367 173.646 272.72L166.319 267.248L150.233 255.088Z'
        fill='#063623'
      />
      <path d='M177 134.5L173.5 125L183.5 121L187 128L177 134.5Z' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_202:1582'>
        <rect width='358' height='281' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
