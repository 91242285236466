import { format as fnsFormat, parse as fnsParse, isBefore } from 'date-fns';
import da from 'date-fns/locale/da';
import { DATEFORMAT } from 'shared/constants/dates';

const isDate = (value) => {
  return value instanceof Date && !isNaN(value.valueOf());
};

const format = (date: Date, format = DATEFORMAT.doMMMMyyyy) =>
  isDate(date) ? fnsFormat(date, format, { locale: da }) : undefined;

const parse = (input: string, format = DATEFORMAT.doMMMMyyyy) => {
  const parsed = fnsParse(input, format, new Date(), { locale: da });
  return isDate(parsed) && isBefore(new Date(0), parsed) ? parsed : undefined;
};

/**
 * Formats a date to locale date string.
 * @param date Any valid value supported by https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
 * @param locale Any valid locale string.
 * @param options Any valid combination of Intl.DateTimeFormatOptions https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @returns Date as string in specified locale. Danish date format by default.
 */
export function localeDate(
  date: string | number | Date,
  locale: string | string[] = 'da-DK',
  options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
): string {
  return new Date(date).toLocaleDateString(locale, options);
}

export const dates = { isDate, format, parse };
