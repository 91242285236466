import { createNanoEvents } from 'nanoevents';

export type NavId = 'nav-selfcare';

interface Events {
  toggle: (side: NavId) => void;
  open: (side: NavId) => void;
  close: (side: NavId) => void;
  link: (address: string) => void;
}

export const events = createNanoEvents<Events>();
