import React, { FC } from 'react';

export const LeftNavSubscriptionsLoader: FC = () => {
  return (
    <div className='nav-xhr-loader'>
      <div className='nav-xhr-loader__text'>Henter dine abonnementer</div>
      <div className='nav-xhr-loader__animation'>
        <div className='nav-xhr-loader__dot'>&nbsp;</div>
        <div className='nav-xhr-loader__dot'>&nbsp;</div>
        <div className='nav-xhr-loader__dot'>&nbsp;</div>
      </div>
    </div>
  );
};
