import Cookie from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { local } from 'shared/utils/storage/local-storage';
import { isUsingImpersonation } from 'utils/impersonation-utils';
import { isUsingMock } from 'utils/mock';
import css from './ImpersonationBanner.module.scss';

const employee = isUsingMock ? 'mock-employee' : Cookie.get('impersonation-employee');
const user = isUsingMock ? 'mock-user' : Cookie.get('impersonation-user');
const signOutUrl =
  'https://id.yousee.dk/logout?timeout=2&returnUrl=https://nextgen-impersonation.yousee.dk/';

export const ImpersonationBanner = () => {
  const [scrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 40;
      setScrolled(isScrolled);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (isUsingImpersonation) {
    document.body.classList.add('impersonation-mode');

    const endSession = (e: any) => {
      e.preventDefault();
      if (isUsingMock) {
        local.remove('mocksetting_impersonation');
        window.location.reload();
        return;
      }
      Cookie.remove('impersonation-user', '.yousee.dk');
      Cookie.remove('impersonation-employee', '.yousee.dk');
      location.href = signOutUrl;
    };

    return (
      <div
        className={
          scrolled ? css.impersonationBannerWrapperScrolled : css.impersonationBannerWrapper
        }>
        <aside className={css.impersonationBanner}>
          <p className={css.impersonationBannerText}>
            <b>{employee}</b> er logget ind på vegne af <b>{user}</b>
            <a
              id='endImpersonation'
              href={signOutUrl}
              className={css.impersonationBannerLink}
              onClick={endSession}>
              Afslut session
            </a>
          </p>
        </aside>
      </div>
    );
  }

  return null;
};
