// Signicat params
import { generateCodeChallenge, getCodeVerifier, getState } from './utils/crypto-helper';

// API token call
import { getMitIDAccessToken } from './utils/mit-id.api';

// Constants
import {
  getFromSessionStorage,
  removeFromSessionStorage,
  setInSessionStorage,
} from 'utils/storage';
import {
  MITID_BASE_URL,
  MITID_ERROR,
  MITID_QUERY_ERROR,
  MITID_QUERY_SUCCESS,
  labels,
} from './labels';
export interface MitIdParams {
  token: string;
}

// Mit-Id Errors related
export const setMitIdError = (error) => setInSessionStorage(labels.mitID_error, error);

export const getMitIdError = () => getFromSessionStorage(labels.mitID_error);

export const getMitIdErrorMsg = (code) => MITID_ERROR[code] || MITID_ERROR.default;

export const removeMitIdError = () => removeFromSessionStorage(labels.mitID_error);

/**
 *  Fix on browser history when mitID error and user clicks on browser navigation:
 * preventing user to land in mit-id login from browser back arrow.
 *    1. By default, we can set a default value of -2.
 *    2. When invalid_cpr error is retrieved, an extra redirect happened, then we need
 *    to remove this extra step from the browser history.
 */
export const handleMitIDErrorCodes = () => {
  const pageQueryParams = new URLSearchParams(window.location.search);
  const error = pageQueryParams.get(MITID_QUERY_ERROR);
  let backOnHistory = -2;

  if (error) {
    setMitIdError(error);
    if (error === MITID_ERROR.invalid_cpr.id) backOnHistory--;
    window.history.go(backOnHistory);
  }
};

export const isMitIDInParams = () => {
  const queryParams = window.location.search;
  return getMitIdError() || queryParams.includes(MITID_QUERY_SUCCESS);
};

// Redirect to Signicat call
export const initiateMitIDFlow = async () => {
  const redirectUri = encodeURIComponent(window.location.origin + window.location.pathname);
  const codeChallenge = await generateCodeChallenge();
  const state = getState();
  const queryParams = `Redirect=${redirectUri}&State=${state}&Code_Challenge=${codeChallenge}&Code_Challenge_Method=S256`;
  window.location.href = MITID_BASE_URL + '/authorize?' + queryParams;
};

// Handling query params after MitID login
export const authenticateMitID = async (success) => {
  const pageQueryParams = new URLSearchParams(window.location.search);
  const error = getMitIdError();
  const code = pageQueryParams.get(MITID_QUERY_SUCCESS);
  const codeVerifier = getCodeVerifier();

  // If error code => show error notification
  if (error) {
    removeMitIdError();
    return error;
  }

  // If success => check code_verifier and mitID code match, then retrieve access token
  else if (code && codeVerifier) {
    const accessToken = await getMitIDAccessToken(code, codeVerifier);
    if (!accessToken) return MITID_ERROR.default.id;
    success({ token: accessToken });
  }

  return;
};
