import React, { SVGProps } from 'react';

export const LadyInChairError = ({
  width = 345,
  height = 273,
  ...rest
}: SVGProps<SVGSVGElement>) => (
  <svg
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 345 273'
    width={width}
    height={height}
    {...rest}>
    <rect x='7.291' y='127.524' width='337.709' height='6.751' rx='3.375' fill='#EEE' />
    <path fill='#EEE' d='M297.061 132.092h3.677v13.328h-3.677z' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M298.899 265.897a3.447 3.447 0 01-3.447-3.447V142.976a3.447 3.447 0 116.894 0V262.45a3.447 3.447 0 01-3.447 3.447z'
      fill='#EEE'
    />
    <path fill='#EEE' d='M58.177 132.092H54.5v13.328h3.677z' />
    <rect
      width='125.885'
      height='6.894'
      rx='3.447'
      transform='matrix(0 -1 -1 0 59.786 265.413)'
      fill='#EEE'
    />
    <mask
      id='a'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='184'
      y='123'
      width='75'
      height='5'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M184.49 123.311a.395.395 0 00-.395.394v1.675a2.07 2.07 0 002.069 2.069h70.342a2.07 2.07 0 002.069-2.069v-1.675a.395.395 0 00-.394-.394H184.49z'
        fill='#fff'
      />
    </mask>
    <g mask='url(#a)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M183.109 128.434h76.45v-6.108h-76.45v6.108z'
        fill='#9B9B9B'
      />
    </g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M194.107 75.907c-.87 0-1.658.705-1.759 1.576l-5.17 44.548c-.101.87.522 1.576 1.393 1.576H254.1c.87 0 1.658-.706 1.759-1.576l5.17-44.548c.102-.87-.522-1.576-1.393-1.576h-65.529z'
      fill='#383838'
    />
    <mask
      id='b'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='187'
      y='75'
      width='75'
      height='49'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M194.107 75.907c-.87 0-1.658.705-1.759 1.576l-5.17 44.548c-.101.87.522 1.576 1.393 1.576H254.1c.87 0 1.658-.706 1.759-1.576l5.17-44.548c.102-.87-.522-1.576-1.393-1.576h-65.529z'
        fill='#fff'
      />
    </mask>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M189.648 120.916h63.481l5.007-41.915h-63.481l-5.007 41.915z'
      fill='#FAE8E8'
    />
    <mask
      id='c'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='189'
      y='79'
      width='70'
      height='42'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M189.648 120.916h63.481l5.007-41.915h-63.481l-5.007 41.915z'
        fill='#fff'
      />
    </mask>
    <path
      d='M151.661 244.507a34.315 34.315 0 01-1.016-1.299l.232-.274a234.35 234.35 0 001.732-2.079c1.309-1.59 2.834-3.494 3.556-4.589.699-1.062 1.615-2.567 2.351-3.796.344-.576.651-1.094.88-1.483l8.301 3.03c-.347.566-.81 1.319-1.319 2.141-1.085 1.754-2.372 3.813-3.2 5.071-.86 1.306-2.141 3.537-3.194 5.415-.529.945-1.006 1.81-1.351 2.439l-.133.243c-.253-.104-.553-.23-.881-.376-.98-.434-2.176-1.019-3.131-1.648-.887-.584-1.946-1.723-2.827-2.795zM209.139 255.946a76.595 76.595 0 01-.661-.192c.014-.29.027-.649.032-1.061a31.126 31.126 0 00-.235-4.353 411.275 411.275 0 00-.7-5.161l8.469-.585c.006.225.014.48.028.757.055 1.104.192 2.595.527 4.115.344 1.559.855 2.622 1.31 3.316.228.346.439.597.605.77.042.043.08.082.115.115l.014.028c.06.126.145.308.238.53.19.449.405 1.032.537 1.616.137.607.154 1.093.066 1.407a.576.576 0 01-.145.269c-.05.048-.146.115-.348.158-.528.113-1.468.081-2.7-.093-1.194-.169-2.542-.452-3.819-.758a80.168 80.168 0 01-3.333-.878z'
      fill='#fff'
      stroke='#484848'
      strokeWidth='2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M142.266 184.018c9.541 0 21.072-30.177 21.072-30.177l-9.497-14.886 19.181-52.816s2.573-13.67-9.684-15.949c-12.256-2.278-27.342-2.91-27.342-2.91s-15.933-1.139-14.793 14.303c0 10.399 7.048 24.64 7.027 43.797-.021 19.158-7.03 23.967-7.027 39.331.003 15.363 11.522 19.307 21.063 19.307z'
      fill='#1FAB2E'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M181.131 169.966l-23.943 63.816 7.942 6.599 37.56-59.757-21.559-10.658z'
      fill='#77ACC7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M218.845 173.193l-1.488 72.447-10.998 1.529s-4.385-20.881-6.37-34.279c-1.985-13.398 0-39.697 0-39.697h18.856z'
      fill='#77ACC7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M169.943 127.556l11.103-6.279-12.154-35.837-13.959 5.019 15.01 37.097z'
      fill='#fff'
      stroke='#484848'
      strokeWidth='2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M209.878 125.801c5.626.007 8.744-3.464 8.744-3.464l9.572-.058c.958-.001 1.195-.003 1.34-.36.145-.357.006-.674-.763-.985-.769-.311-11.895-4.895-11.913-4.927-.525-.211-1.324-.204-1.76.014-1.653.828-5.968 4.046-5.968 4.046l-28.524-.947s-6.051-2.045-8.799.067c-5.988 4.605-1.883 12.61 5.148 12.556 7.031-.053 32.923-5.942 32.923-5.942z'
      fill='#fff'
      stroke='#484848'
      strokeWidth='2'
    />
    <path
      d='M155.34 30.284c-6.522 0-11.34 5.207-11.34 14.927l-3.06 25.144s3.06 4.392 7.85 4.392c4.789 0 6.55-4.392 6.55-4.392l-1.637-7.687c8.81 0 10.917-9.333 10.917-9.333h3.276l-2.184-5.49c0-12.08-2.653-17.56-10.372-17.56z'
      fill='#fff'
    />
    <path
      d='M164.62 53.335s-2.107 9.333-10.917 9.333l1.637 7.687s-1.761 4.392-6.55 4.392c-4.79 0-7.85-4.392-7.85-4.392L144 45.21c0-9.72 4.818-14.927 11.34-14.927 7.719 0 10.372 5.482 10.372 17.56l2.184 5.49m-3.276 0h3.276m-3.276 0h3.276'
      stroke='#484848'
      strokeWidth='2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M148.797 25.818c-20.556 0-17.27 21.167-17.27 21.167l9.908 13.568s9.687-7.403 11.967-13.568c1.053-2.847 3.135-8.141 3.135-8.141l9.531 3.256s.429-16.282-17.271-16.282z'
      fill='#063623'
    />
    <ellipse cx='151.631' cy='46.718' rx='4.242' ry='5.009' fill='#fff' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M172.452 77.595l4.557 21.646-22.215 5.696-2.627-16.415 20.285-10.927z'
      fill='#1FAB2E'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M218.491 177.176c-1.754 7.44-18.307 6.849-18.307 6.849h-64.757s-16.519-20.717 0-22.995c16.519-2.279 26.772-9.384 26.772-9.384s36.571 7.094 46.919 9.384c7.994 1.769 11.128 8.706 9.373 16.146z'
      fill='#77ACC7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M137.879 182.427l-2.75-.947-4.339 12.603 2.75.946 4.339-12.602z'
      fill='#063623'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M108.372 264.273a3.078 3.078 0 01-1.552-3.723l23.06-69.751a3.08 3.08 0 015.846 1.933l-23.061 69.75a3.078 3.078 0 01-4.293 1.791z'
      fill='#003025'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M169.027 182.427l2.749-.947 4.339 12.602-2.749.947-4.339-12.602z'
      fill='#063623'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M198.533 264.272a3.078 3.078 0 001.553-3.723l-23.061-69.75a3.077 3.077 0 00-4.293-1.79 3.078 3.078 0 00-1.553 3.723l23.061 69.75a3.078 3.078 0 004.293 1.79z'
      fill='#003025'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M105.674 96.524s5.524-2.127 12.11-2.127c6.587 0 22.488 9.926 25.447 42.358 2.959 32.431 4.958 34.755 29.233 35.229 24.275.474 29.021 12.21 29.021 12.21s-12.773-.044-25.843-.044h-41.306c-22.502 0-24.613-19.409-23.138-45.537 1.475-26.129-5.524-42.09-5.524-42.09z'
      fill='#191919'
    />
    <g clipPath='url(#clip0)'>
      <path
        clipRule='evenodd'
        d='M221.897 111.821c7.719.488 14.343-4.887 14.793-12.007.451-7.12-5.442-13.288-13.161-13.776-7.719-.489-14.343 4.887-14.793 12.007-.451 7.119 5.442 13.287 13.161 13.776z'
        stroke='#D31A20'
        strokeWidth='2'
      />
      <path
        d='M227.331 97.699c1.283.081 2.101-.582 2.175-1.766.075-1.184-.652-1.945-1.935-2.026-1.283-.081-2.101.582-2.175 1.766-.075 1.184.652 1.945 1.935 2.026zM218.33 97.13c1.284.08 2.101-.583 2.176-1.767.075-1.183-.652-1.944-1.936-2.025-1.283-.082-2.1.581-2.175 1.765-.075 1.184.652 1.945 1.935 2.026z'
        fill='#D31A20'
      />
      <path
        d='M216.398 105.342c2.551-5.289 9.907-4.823 11.771.745'
        stroke='#D31A20'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </g>
    <path stroke='#EEE' strokeWidth='3' d='M2 2h59.365v74.207H2z' />
    <g clipPath='url(#clip1)'>
      <path
        d='M48.309 54.395L37.216 49l11.093-5.395c.617-.309.868-1.06.559-1.677l-1.118-2.237a1.25 1.25 0 00-1.677-.559L31.5 46.219l-14.573-7.087a1.25 1.25 0 00-1.677.56l-1.118 2.236a1.25 1.25 0 00.559 1.677L25.783 49l-11.092 5.395a1.25 1.25 0 00-.558 1.678l1.118 2.236a1.25 1.25 0 001.677.559L31.5 51.781l14.573 7.087a1.25 1.25 0 001.677-.559l1.118-2.236a1.25 1.25 0 00-.56-1.678zm-22.59-16.858l-.428 2.022c-.209.986.423 1.941 1.285 1.941h9.85c.861 0 1.493-.956 1.285-1.941l-.43-2.021c3.264-1.751 5.469-4.903 5.469-8.538 0-5.523-5.037-10-11.25-10s-11.25 4.477-11.25 10c0 3.635 2.205 6.787 5.469 8.538zm10.156-9.787c1.379 0 2.5 1.121 2.5 2.5s-1.121 2.5-2.5 2.5a2.502 2.502 0 01-2.5-2.5c0-1.379 1.121-2.5 2.5-2.5zm-8.75 0c1.379 0 2.5 1.121 2.5 2.5s-1.121 2.5-2.5 2.5a2.502 2.502 0 01-2.5-2.5c0-1.379 1.121-2.5 2.5-2.5z'
        fill='#F0F0F0'
      />
    </g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M138.352 90.65c-.771-11.742-4.06-17.915-6.511-25.13-.498-1.472-4.393-9.912 2.812-17.51 2.384-3.982-2.565-5.786-2.565-5.786s-21.097 10.61-16.319 36.992c2.634 18.17-8.726 28.846 1.218 35.717 10.22 7.06 22.827-2.044 21.365-24.283z'
      fill='#063623'
    />
    <path
      d='M206.914 264.435l.168-12.922 10.797-1.121 5.636 4.7 12.336 3.551c1.038.299.93 1.804-.141 1.951l-8.956 1.231-19.84 2.61zM144.148 246.216l9.19-9.086 8.477 6.78.716 7.304 6.294 11.188c.53.942-.604 1.938-1.47 1.291l-7.243-5.41-15.964-12.067z'
      fill='#063623'
    />
    <path d='M170.712 126.542l-3.473-9.428 9.924-3.97 3.473 6.947-9.924 6.451z' fill='#fff' />
    <defs>
      <clipPath id='clip0'>
        <path fill='#fff' transform='rotate(3.621 -1204.704 3318.86)' d='M0 0h32.953v30.393H0z' />
      </clipPath>
      <clipPath id='clip1'>
        <path fill='#fff' transform='translate(14 19)' d='M0 0h35v40H0z' />
      </clipPath>
    </defs>
  </svg>
);
