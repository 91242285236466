import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system/styleFunctionSx';
import React from 'react';

type CardContentProps = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export const CardContent = ({ children, sx }: CardContentProps) => (
  <Box sx={{ p: { xs: 4, sm: 5 }, typography: 'regular16', ...sx }}>{children}</Box>
);
