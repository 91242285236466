import { Box, Typography } from '@mui/material';
import { colors } from '@nuuday/yousee-dna/generated/colors';
import { ArrowLeft } from '@nuuday/yousee-dna/generated/icons/general';
import React from 'react';
import { Link } from 'react-router-dom';

export type PageHeaderBackLinkProps = {
  href: string;
  text: string;
  useRouterLink?: boolean;
};

export default function PageHeaderBackLink({
  href,
  text,
  useRouterLink,
}: PageHeaderBackLinkProps): JSX.Element {
  const content = (
    <Box
      sx={{
        'display': 'flex',
        'alignItems': 'center',
        'color': colors.Text,
        '& > svg': {
          marginLeft: -2,
        },
      }}>
      <ArrowLeft width={32} height={32} />
      <Typography variant='regular16' sx={{ ml: 2 }}>
        {text}
      </Typography>
    </Box>
  );

  return useRouterLink ? (
    <Link to={href} style={{ textDecoration: 'none' }}>
      {content}
    </Link>
  ) : (
    <Box component='a' href={href} sx={{ textDecoration: 'none' }}>
      {content}
    </Box>
  );
}
