import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { AlertTitle, Box, Container, Alert as MuiAlert } from '@mui/material';
import { colors } from '@nuuday/yousee-dna/generated/colors';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { Entry } from 'contentful';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getAlerts, isPreviewEnabled, TypeAlertsFields } from 'api/contentful-api';
import { isUserAgent } from 'utils/user-agent';

import css from './index.module.scss';

const ignoredPages = ['/cookie-information'];

export const Alert = () => {
  const location = useLocation();
  const [shownAlerts, setShownAlerts] = useState<Entry<TypeAlertsFields>[]>([]);
  const { data: alerts, error } = useQuery<Entry<TypeAlertsFields>[]>(
    ['alerts'],
    () =>
      getAlerts({
        preview: isPreviewEnabled(new URLSearchParams(location.search)),
      }),
    {
      refetchInterval: 60 * 1000,
    },
  );

  useEffect(() => {
    const path = location.pathname;

    if (ignoredPages.includes(path)) {
      return;
    }

    const activeAlerts = alerts
      // filter out alerts that are not active on the current path
      ?.filter((alert) => {
        // make leading slash optional in CF
        const sanitizedSlug = alert.fields.slug.startsWith('/')
          ? alert.fields.slug.replace('/', '')
          : alert.fields.slug;

        if (alert.fields.distinctMatchSlug) {
          return path === alert.fields.slug;
        }

        return path.includes(sanitizedSlug);
      })
      // filter out alerts that have either expired or are scheduled later
      .filter((alert) => {
        const isFinished = alert.fields.end ? new Date(alert.fields.end) < new Date() : false;
        const isStarted = new Date(alert.fields.start) <= new Date() || !alert.fields.start;
        return isStarted && !isFinished;
      })
      // filter out alerts that should not be shown in the MYS App
      .filter((x) =>
        isUserAgent(['youseeSelfcareApp']) && x.fields.showInMYSApp === false ? false : true,
      );

    if (activeAlerts) {
      setShownAlerts(activeAlerts);
    }
  }, [location.pathname, alerts]);

  if (error) {
    Sentry.captureException(error);
    return null;
  }

  if (shownAlerts.length === 0) {
    return null;
  }

  return (
    <Box sx={{ background: colors.White, py: 3 }}>
      <Container
        maxWidth='md'
        sx={{
          ['> * + *']: {
            mt: { xs: 3, sm: 4 },
          },
        }}>
        {shownAlerts.map((alert) => {
          const type =
            alert.fields.type === 'Error (red)'
              ? 'error'
              : alert.fields.type === 'Warning (yellow)'
              ? 'warning'
              : 'info';
          return (
            <MuiAlert key={alert.sys.id} severity={type}>
              <AlertTitle>{alert.fields.title}</AlertTitle>
              {/* @ts-ignore interface error, it works though */}
              <Box className={css.prose}>{documentToReactComponents(alert.fields.body)}</Box>
            </MuiAlert>
          );
        })}
      </Container>
    </Box>
  );
};
