import { get } from 'shared/utils/api';

const requestMap = {
  menuoverview: '/yousee/selfcare/products/menuoverview',
  userprofile: '/yousee/selfcare/userprofile',
  benefitprofile: '/yousee/selfcare/customer/benefitprofile',
  customeraffiliation: '/yousee/selfcare/youseemore/customeraffiliation',
  emailaccess: '/yousee/selfcare/emails/hassubscription',
  hasserviceaccess: '/yousee/selfcare/products/hasserviceproducts',
  hasautomaticcardpayment: '/yousee/selfcare/invoice/hasautomaticcardpayment', // https://wiki.tdc.dk/display/YSS/3.6+Has+Automatic+Card+Payment
  notificationstypes: '/yousee/selfcare/notifications/types', // https://wiki.tdc.dk/display/YSS/Technical+blueprint
};

function Request(url: keyof typeof requestMap, resolve, reject) {
  const requestMapUrl = requestMap[url] || false;
  if (!requestMapUrl) {
    return reject({
      status: 'n/a',
      statusText: `Url: ${url} not found in requestMap`,
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  get({ url: requestMapUrl, baseHost: 'ODIN', params: { _: Date.now() } })
    .then((response) => {
      // Check for some accounts
      if (!response) {
        return reject({
          status: 204,
          statusText: 'No Content',
        });
      }

      return resolve(response);
    })
    .catch((error) => {
      return reject(error);
    });
}

export default Request;
