/**
 * Will give a compilation error if `param` is not type `never` (eg. all cases handled in a switch statement)
 * @returns param
 */

export function exhaustiveCheck(param: string) {
  if (process.env.NODE_ENV === 'development')
    console.error(
      `Hit exhaustiveCheck with param: ${param}. Probably the mock-data needs to be updated or there is a bug in the code`,
    );
  return param;
}
