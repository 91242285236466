import { useEffect, useState } from 'react';
import Request from '../../components/navigation/Utils/lib/request';
import {
  ColumbusSubscriptionRawDataInterface,
  ColumbusSubscriptionType,
} from './useMyColumbusSubscriptionsTypes';
import { fetchColumbusSubscriptionsDetails } from './useMyColumbusSubscriptionsUtils';

export const useMyColumbusSubscriptions = () => {
  const [columbusSubscriptions, setColumbusSubscriptions] = useState<ColumbusSubscriptionType[]>(
    [],
  );
  const [columbusSubscriptionsError, setColumbusSubscriptionsError] = useState<boolean>(false);
  const [loadingColumbusSubscriptions, setLoadingColumbusSubscriptions] = useState<boolean>(true);
  useEffect(() => {
    Request(
      'menuoverview',
      async (productList: ColumbusSubscriptionRawDataInterface[]) => {
        const columbusSubscriptionsWithDetails =
          await fetchColumbusSubscriptionsDetails(productList);
        setLoadingColumbusSubscriptions(false);
        setColumbusSubscriptions(columbusSubscriptionsWithDetails);
      },
      () => {
        // Error
        setLoadingColumbusSubscriptions(false);
        setColumbusSubscriptionsError(true);
      },
    );
  }, []);

  return {
    columbusSubscriptions,
    columbusSubscriptionsError,
    loadingColumbusSubscriptions,
  };
};
