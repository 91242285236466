import { Box, Button, Typography } from '@mui/material';
import { LadyInChairError } from 'components/img/LadyInChairError';
import React from 'react';

export default function NotFound() {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          margin: 'auto',
          paddingBlock: '8rem 0rem',
          maxWidth: { xs: '320px', sm: '576px', md: '768px' },
        }}>
        <Typography variant='h2'>Ups, der er sket en fejl</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          margin: 'auto',
        }}>
        <Typography sx={{ marginTop: '2em', overflowWrap: 'break-word', display: 'block' }}>
          Den side du har forsøgt at åbne er enten blevet flyttet eller fjernet.
        </Typography>
        <Typography>Tryk her for at komme til vores forside.</Typography>
        <Button
          variant='cta'
          href='https://yousee.dk'
          target='_self'
          sx={{
            margin: '2rem auto',
          }}>
          Gå til forsiden
        </Button>
        <LadyInChairError />
      </Box>
    </>
  );
}
