import { NetCrackerSubscriptionType } from 'api/netCrackerAPI/netCrackerApiTypes';
import { events } from 'components/navigation/Utils/events';
import { ColumbusSubscriptionType } from 'hooks/useMyColumbusSubscriptions/useMyColumbusSubscriptionsTypes';
import {
  getIconName,
  getUrl,
  GroupCountType,
  includeSubscriptionDetails,
} from 'hooks/useMyColumbusSubscriptions/useMyColumbusSubscriptionsUtils';
import React, { FC } from 'react';
import { getConfig } from 'shared/config';
import { SubscriptionNavItemDetails } from '../../SubscriptionNavItemDetails';
import { LeftNavSubscriptionIcon } from '../LeftNavSubscriptionIcon';

type SubscriptionsNavItemProps = {
  subscription: NetCrackerSubscriptionType | ColumbusSubscriptionType;
  index: number;
  subscriptionsLength: number;
  groupCount: GroupCountType;
};

const standardLinks = [
  'Fixednet=/fastnet',
  'Mobile=/mobil',
  'MobileBroadband=/mobiltbredbaand',
  'Broadband=/bredbaand',
  'NetBasis=/netbasis',
  'Tv=/tv',
];

export const SubscriptionsNavItem: FC<SubscriptionsNavItemProps> = ({
  subscription,
  index,
  subscriptionsLength,
  groupCount,
}) => {
  const prefixMap = {};

  standardLinks.forEach((value) => {
    const parts = value.split('=');
    prefixMap[parts[0]] = parts[1];
  });

  const link = getUrl(
    prefixMap,
    subscription.productType,
    subscription,
    subscription.isNetCrackerSubscription,
  );

  const { SPA } = getConfig().navigation || {};

  const SPALink = (e) => {
    if (link[0] !== '/') return;
    e.preventDefault();
    events.emit('link', link);
  };

  const includeDetails = includeSubscriptionDetails(subscription, groupCount);

  return (
    <li
      className={`navpanel__item navpanel__item--subscription ${
        index === 0 ? 'navpanel__item--subscription-first' : ''
      }
  ${index === subscriptionsLength - 1 ? 'navpanel__item--subscription-last' : ''}`}>
      <a
        id={subscription.subscriptionId}
        className='navpanel__item-link'
        href={subscription ? link : '#'}
        onClick={SPA ? SPALink : undefined}>
        {getIconName(subscription.productType) && (
          <LeftNavSubscriptionIcon iconName={getIconName(subscription.productType)} />
        )}
        {subscription.productName}
        {includeDetails && <SubscriptionNavItemDetails subscription={subscription} />}
      </a>
    </li>
  );
};
