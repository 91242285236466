function navAccessibility(this: any) {
  const domBody = document.body;
  const navButtons = document.getElementsByClassName('navheader__button');
  let domTabItems: HTMLElement | null = null;
  let domFirstInput: HTMLElement | null = null;
  let domLastInput: HTMLElement | null = null;
  let domExit: HTMLElement | null = null;
  let target = 'false';
  let mouseDown = false;

  // Event Listernes for keyboard focus
  for (let i = 0; i < navButtons.length; i++) {
    const domNavButton = navButtons[i];
    domNavButton.addEventListener('blur', () => domNavButton.classList.remove('is-focused'));
    domNavButton.addEventListener('mousedown', () => (mouseDown = true));
    domNavButton.addEventListener('mouseup', () => (mouseDown = false));

    domNavButton.addEventListener('focus', (e: Event) => {
      domNavButton.classList.add('is-focused');

      if (mouseDown) {
        //@ts-ignore
        e.target.blur();
      }
    });
  }

  // Methods for navigating with keyboard
  domBody.addEventListener('keydown', (e) => {
    if (e.which === 13 && document.activeElement?.className) {
      target = document.activeElement.className;
    }
  });

  function tabFirst(e) {
    if (e.which === 9 && e.shiftKey) {
      e.preventDefault();
      domExit?.focus();
    }
  }

  function tabLast(e) {
    if (e.which === 9 && !e.shiftKey) {
      e.preventDefault();
      domExit?.focus();
    }
  }

  function tabExit(e) {
    if (e.which === 9 && !e.shiftKey) {
      e.preventDefault();
      domFirstInput?.focus();
    } else if (e.which === 9) {
      e.preventDefault();
      domLastInput?.focus();
    }
  }

  // Method for creating and destroying accessibility event handelers
  this.able = (param) => {
    if (typeof target.includes !== 'function') {
      return;
    }
    if (target.includes('navheader__button')) {
      // Create accessibility event handelers
      if (param === 'create') {
        domTabItems = domBody.getElementsByClassName('navpanel is-active')[0] as HTMLElement;
        for (
          let i = 0;
          i < domTabItems.getElementsByClassName('navpanel__item')[0].childNodes.length;
          i++
        ) {
          if (
            domTabItems.getElementsByClassName('navpanel__item')[0].childNodes[i].nodeName === 'A'
          ) {
            domFirstInput = domTabItems.getElementsByClassName('navpanel__item')[0].childNodes[
              i
            ] as HTMLElement;
          } else if (
            domTabItems.getElementsByClassName('navpanel__item')[0].childNodes[i].nodeName ===
            'FORM'
          ) {
            domFirstInput = domTabItems.getElementsByClassName('navpanel__item')[0].childNodes[i]
              .childNodes[1] as HTMLElement;
          }
        }
        domLastInput = domTabItems.getElementsByClassName('navshortcuts__link')[
          domTabItems.getElementsByClassName('navshortcuts__link').length - 1
        ] as HTMLElement;
        domExit = domBody.getElementsByClassName('navheader__button is-active')[0] as HTMLElement;
        domExit.focus();
        domFirstInput?.addEventListener('keydown', tabFirst);
        domLastInput.addEventListener('keydown', tabLast);
        domExit.addEventListener('keydown', tabExit);
      } else if (param === 'destroy') {
        target = 'false';
        domFirstInput?.removeEventListener('keydown', tabFirst);
        domLastInput?.removeEventListener('keydown', tabLast);
        domExit?.removeEventListener('keydown', tabExit);
        domExit?.focus();
      }
    }
  };
}

export default navAccessibility;
