interface CurrencyOptionsProps {
  precision?: number;
  thousands?: boolean;
  showDecimal?: boolean;
}

const format = (
  input: string | number,
  { showDecimal = false, precision = 2, thousands = true }: CurrencyOptionsProps = {},
): string => {
  if (typeof input === 'string') {
    input = parseFloat(input);
  }

  const precisionMultiplier = Math.pow(10, precision);
  const roundedValue = Math.round(input * precisionMultiplier) / precisionMultiplier;

  let [integerPart, fractionalPart = ''] = roundedValue.toString().split('.');

  // handle thousands
  if (thousands && integerPart.length > 3) {
    const isNegative = roundedValue < 0;
    // removing the minus
    if (isNegative) {
      integerPart = integerPart.replace('-', '');
    }

    const remainder = integerPart.length % 3;

    // generate padding based on the remainder
    // e.g. '1234' will be '001234'
    const integerPadding = '0'.repeat([0, 2, 1][remainder]);
    integerPart = integerPadding + integerPart;

    // matching pairs of 3 digits and adding dot in bettwen them
    // e.g. '001234' will be '001.234'
    integerPart = integerPart.match(/(\d{1,3})/g)?.join('.') ?? '';

    // remove the padding
    // e.g. '001.234' will be '1.234'
    integerPart = integerPart.replace(integerPadding, '');

    // adding back the minus
    if (isNegative) {
      integerPart = '-' + integerPart;
    }
  }

  // handle showDecimal
  if (showDecimal && fractionalPart.length < precision) {
    fractionalPart += '0'.repeat(precision - fractionalPart.length);
  }

  if (fractionalPart) {
    return `${integerPart},${fractionalPart} kr.`;
  }

  return `${integerPart} kr.`;
};

export const currency = { format };
