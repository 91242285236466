import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system/styleFunctionSx';
import React, { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  sx?: SxProps<Theme>;
};

export const InlineBlockWrapper = ({ children, sx }: Props) => (
  <Box sx={{ ...sx, display: 'inline-block' }} component='span'>
    {children}
  </Box>
);
