import { InlineBlockWrapper } from 'components/InlineBlockWrapper/InlineBlockWrapper';
import { CustomerSubscription } from 'interfaces/ApiTypeAliases';
import { components as paymentGateway } from 'interfaces/api/payment-gateway';
import React from 'react';
import { Address } from 'shared/interfaces/address';
import { address as addressFormatter } from 'shared/utils/formatting/address';
import { currency } from 'shared/utils/formatting/currency';
import { dates } from 'shared/utils/formatting/dates';
import { strings } from 'shared/utils/formatting/strings';
import { exhaustiveCheck } from './exhaustiveCheck';

/** Example: [BROADBAND, TV] => Bredbånd, TV */
export function formatPayingForProducts(products: PayingForProduct[]) {
  return !products?.length
    ? 'Kontoen har ingen produkter tilknyttet'
    : products.map((product) => translateProduct(product)).join(', ');
}

export const formatCurrency = (amount: number) => currency.format(amount, { showDecimal: true });
export const formatDate = (date: string | Date, format = 'do MMM yyyy') =>
  dates.format(new Date(date), format);
export const formatCurrencyMonthly = (amount: number) => `${formatCurrency(amount)}/md.`;

export const formatCreditCard = (creditCard: string, separator = ' '): string =>
  creditCard && strings.chunkString(creditCard.replace(/X/g, '*'), 4).join(separator);

export const formatAccountNumber = (accNumber: string, separator = ' '): string =>
  accNumber && strings.chunkString(accNumber.match(/\d+$/)?.[0] || '', 3)?.join(separator);

export const formatPhoneNumber = (phoneNumber: string) =>
  phoneNumber && strings.twoByTwo(phoneNumber);

export const formatPhoneNumberNoLinebreak = (phoneNumber: string) => {
  return <InlineBlockWrapper>{phoneNumber && strings.twoByTwo(phoneNumber)}</InlineBlockWrapper>;
};

export const formatAddress = (address: Address, inclCity?: boolean) =>
  address && addressFormatter.address(address, inclCity);

/**
 * Convert bytes to human-readable value unit.
 * @param bytes Number of bytes to convert.
 * @param decimals Decimals in return string.
 * @param withUnit Include byte size in return string.
 */
export const formatBytes = (bytes: number, decimals = 2, useUnit = false): string => {
  const k = 1024;

  const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; // Byte unit is excluded as we convert bytes to KB as a minimum.

  const unitIndex = Math.floor(Math.log(bytes) / Math.log(k));
  const convertedBytes = useUnit ? bytes / Math.pow(k, unitIndex) : bytes / k;
  let ciphers = decimals;

  if (!useUnit && bytes % k === 0) {
    ciphers = 0;
  }

  const result = convertedBytes.toFixed(ciphers).replace(/\./, ',');

  return useUnit ? `${result} ${sizes[unitIndex]}` : result;
};

export type PayingForProduct = Required<
  paymentGateway['schemas']['Nuuday.Payment.Gateway.Contracts.PaymentAgreement']
>['payingFor'][0];

/** once BE removes dependency on BC we can remove `PayingForProduct` from this */
export const translateProduct = (
  product: PayingForProduct | CustomerSubscription['productType'],
) => {
  switch (product?.toUpperCase()) {
    case 'BROADBAND':
      return 'Bredbånd';
    case 'FIXED':
    case 'BROADBANDTELEPHONY':
    case 'LANDLINE':
      return 'Fastnet telefon';
    case 'MOBILE':
      return 'Mobil';
    case 'MOBILE_BROADBAND':
    case 'MOBILEBROADBAND':
      return 'Mobilt bredbånd';
    case 'TV':
      return 'Tv & Streaming';
    case 'NETBASIS':
      return 'Netbasis';
    case 'Mail':
      return 'Mail';

    // these will never occur and mail is handled elsewhere
    case 'SUSPENDED':
    case 'GOVERNMENT':
    case 'MISCELLANEOUS':
    case 'UNKNOWN':
    case 'PACKAGE':
      return '';
    default: {
      return exhaustiveCheck(product as unknown as string);
    }
  }
};

export const objectToQueryParams = (queryParameters) => {
  if (!queryParameters) return '';
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(queryParameters)) {
    if (value === null || value === undefined) continue;
    params.append(key, value.toString());
  }
  return Array.from(params).length ? `?${params.toString()}` : '';
};

/**
 * Substitutes parameters in the URLs with {id}.
 *
 * Example:
 *  - `/yousee/selfcare/subscriptions/{id}`,
 *  - `/yousee/selfcare/subscriptions/{id}/addonproducts` and so on.
 */
export const getParameterizedUrl = (url: string): string =>
  url
    ?.split('/')
    .filter((seg) => !!seg)
    .map((seg) => {
      return !isNaN(parseInt(seg)) || seg[0] === seg[0].toUpperCase() ? '{id}' : seg;
    })
    .join('/');
