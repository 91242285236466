import { events, NavId } from 'components/navigation/Utils/events';

import transitionEndEvent from './transition-end-event';

class NavButton {
  navId: NavId | null = null;
  btnMain = '';
  btnSelfcare = '';
  openState = false;
  domElement: HTMLElement | null = null;
  domNavPanel: HTMLElement | null = null;
  domNavPanelListElement: Element | null = null;
  domNavPanelParents: NodeListOf<HTMLElement> | null = null;
  currentLevel = 0;
  onClick: (() => void) | null = null;
  onAfterClick: ((openFunc: typeof open) => void) | null = null;

  constructor(domElement: HTMLElement) {
    this.btnMain = 'navheaderButtonMain';
    this.btnSelfcare = 'navheaderButtonSelfcare';
    this.domElement = domElement;

    // Try to get an element pointer from the data-attr.
    this.navId = domElement.getAttribute('data-nav-id') as NavId;
    if (!this.navId) {
      console.log('initialized NavBtn with no navId');
      return;
    }

    // Get the nav panel from the nav id.
    this.domNavPanel = this.navId ? document.getElementById(this.navId) : null;
    if (!this.domNavPanel) {
      console.log('initialized NavBtn with no navPanel');
      return;
    }

    // Cache the main list element in the navPanel
    // ... ie. the first ul tag element.
    this.domNavPanelListElement = this.domNavPanel.querySelector(
      '.navpanel__body > .navpanel__items',
    );
    if (!this.domNavPanelListElement) {
      console.log('initialized NavBtn with no navPanelList');
      return;
    }

    // Get all items with children.
    this.domNavPanelParents = this.domNavPanel.querySelectorAll('.navpanel-item-is-parent');
    this.domElement.onclick = (e) => {
      e.preventDefault();

      // Call the on-click handler if callable.
      if (typeof this.onClick === 'function') {
        this.onClick.call(this);
        return;
      } else {
        this.toggle();
      }

      // Call the onClick callback if callable.
      if (typeof this.onAfterClick === 'function') {
        this.onAfterClick.call(this, open);
      }
    };

    // Set an event-listener for when the slide-animation ends.
    this.domNavPanel.addEventListener(
      transitionEndEvent,
      this.onDomNavPanelTransitionEndEvent.bind(this),
      false,
    );

    // Adds a listener for when the navPanel ends a slide event.
    this.domNavPanelListElement.addEventListener(
      transitionEndEvent,
      this.onDomNavPanelListElementTransitionEndEvent.bind(this),
      false,
    );

    // Default position
    this.slide(0);
  }

  /**
   * An event handler for when the navigation slide transition ends.
   * ... ie. when the navigation changes level.
   *
   * @param  {Event} e The transition event.
   */
  onDomNavPanelListElementTransitionEndEvent(e: Event | null = null) {
    e?.stopPropagation();
    // Do something when menu slide is finished.
  }

  /**
   * The function for sliding the main list element.
   *
   * @param  {number} idx The navigation level number.
   */
  slide(idx) {
    // Remove the old class.
    this.domNavPanelListElement?.classList.remove('navpanel-items-level' + this.currentLevel);

    // Update the current level.
    this.currentLevel = idx === 0 ? idx : this.currentLevel + idx;

    // Adds a new position level class.
    this.domNavPanelListElement?.classList.add('navpanel-items-level' + this.currentLevel);
  }

  /**
   * An event handler for when the nav panel ends its transition.
   * ... ie. opens or closes.
   */
  onDomNavPanelTransitionEndEvent() {
    // Reset the menu on close
    if (!this.openState) {
      this.slide(0);

      // Remove all active parent in the navPanel.
      for (let i = 0; i < (this.domNavPanelParents?.length ?? 0); i++) {
        const domNavPanelParent = this.domNavPanelParents?.[i];
        domNavPanelParent?.classList.remove('navpanel-item-is-active');
      }
    }
  }

  /**
   * Method for returning open state.
   *
   * @return {Boolean} The open state.
   */
  isOpen() {
    return !!this.openState;
  }

  /**
   * Method for toggling the button/panel.
   *
   * @param {Event} e The click event.
   */
  toggle() {
    if (this.openState) {
      this.close();
    } else {
      this.open();
    }

    events.emit('toggle', this.navId as NavId);
  }

  /**
   * Close
   *
   * @param {Event} _e The click event.
   */
  close() {
    this.openState = false;

    // Toggle the button active class
    this.domElement?.classList.remove('is-active');

    // Adding ARIA for screen reader users
    this.domElement?.setAttribute('aria-expanded', 'false');

    // Call the onClick callback if provided.
    events.emit('close', this.navId as NavId);
  }

  /**
   * Open
   */
  open() {
    this.openState = true;

    // Toggle the button active class
    this.domElement?.classList.add('is-active');

    // Adding ARIA for screen reader users
    this.domElement?.setAttribute('aria-expanded', 'true');

    // Call the onClick callback if provided.
    events.emit('open', this.navId as NavId);
  }

  /**
   * Get the button title - ie. inner text.
   *
   * @return {String} The dom element's inner text.
   */
  getTitle() {
    return this.domElement?.innerText || '';
  }

  /**
   * Default on-click event handler.
   *
   * @param  {Event} e The click event
   */
}

export default NavButton;
