import { config } from 'shared/config';
import { MS_HOST, ODIN_API } from 'shared/constants/cookies';

import { storageKeys } from '../../../pages/Mobile/common/constants';
import { cookies } from '../storage/cookies';

export type Host = 'MICRO' | 'ODIN' | 'CUSTOM' | 'MOBILE';

interface GetHost {
  baseHost?: Host;
  apiSegment?: string;
  url?: string;
}

export const getHost = ({ baseHost, apiSegment }: GetHost) => {
  switch (baseHost) {
    case 'ODIN':
      return odinHostUrl();
    case 'MICRO':
      return microHostUrl(apiSegment);
    case 'MOBILE':
      return (
        cookies.get(storageKeys.dccsMsUrlOverride, true) ||
        config?.mobile?.host ||
        import.meta.env.PUBLIC_MOBILE_API_URL
      );
    default:
      return '/';
  }
};

const odinHostUrl = (): string => {
  const odinHostFromCookies = cookies.get(ODIN_API, true);

  let host = odinHostFromCookies;

  if (!host) {
    host = config?.odin?.host;
  }

  if (host.indexOf('://') > 0 || host.indexOf('//') === 0) {
    return host;
  }

  return document.location.origin + host;
};

const microHostUrl = (apiSegment): string => {
  const msHostFromCookies = cookies.get(MS_HOST, true);

  const host = msHostFromCookies || config?.microServices?.host;
  const slash = host && host[host.length - 1] === '/' ? '' : '/';
  const segment = apiSegment ? apiSegment : '';
  return host + slash + segment;
};
