import {
  getFromSessionStorage,
  removeFromSessionStorage,
  setInSessionStorage,
} from 'utils/storage';
import Request from '../lib/request';
import { getUserId } from '../lib/user';

// Show Change card only if there is account with ACP
function ChangeCardPlugin() {
  // Check for data-type = changecard.
  const domElement = document.querySelector('li[data-type="changecard"]');
  if (domElement) {
    const storageKey = getUserId() + '-hasautomaticcardpayment';
    const hasAutomaticCardPayment = getFromSessionStorage(storageKey);

    // if hasautomaticcardpayment in storage and not expired do not make the api call
    if (hasAutomaticCardPayment && hasAutomaticCardPayment.expirationDate) {
      const isExpired =
        new Date(hasAutomaticCardPayment.expirationDate).getTime() - new Date().getTime() < 0;

      if (isExpired) {
        removeFromSessionStorage(storageKey);
      }

      if (!isExpired && hasAutomaticCardPayment.value === false) {
        return domElement.parentNode?.removeChild(domElement);
      }

      if (!isExpired && hasAutomaticCardPayment.value === true) {
        return;
      }
    }

    const content = domElement.innerHTML;
    domElement.innerHTML = '';
    Request(
      'hasautomaticcardpayment',
      (response) => {
        if (response.result === 'Success') {
          // store the response for later use
          setInSessionStorage(storageKey, {
            value: response.payload.hasAutomaticCardPayment,
            expirationDate: new Date(Date.now() + 30 * 60 * 1000), // 30 mins expiritaion
          });

          if (response.payload.hasAutomaticCardPayment) {
            domElement.innerHTML = content;
          } else {
            domElement.parentNode?.removeChild(domElement);
          }
        }
      },
      (err) => {
        console.warn(err);
      },
    );
  }
}

export default ChangeCardPlugin;
