import { leftNavBtn } from 'components/navigation/Components/LeftNav/initLeftNav';
import { events } from 'components/navigation/Utils/events';
import React, { DetailedHTMLProps, ReactNode, useEffect, useState } from 'react';

import cx from 'classnames';
import AnimatedNavPanelLinks from 'components/navigation/Utils/animations/navpanel';
import transitionEndEvent from 'components/navigation/Utils/lib/transition-end-event';
import { getConfig } from 'shared/config';
import styles from './NavItem.module.scss';

interface NavItemProps
  extends DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  link: string;
  text: string;
  icon?: string;
}

export const NavItem = ({ link, text, icon, ...rest }: NavItemProps) => {
  const { SPA } = getConfig().navigation ?? {};

  const SPALink = (e) => {
    if (link[0] !== '/') return;
    e.preventDefault();
    events.emit('link', link);
  };

  return (
    <li className='navpanel__item' {...rest}>
      <a className='navpanel__item-link' href={link} onClick={SPA ? SPALink : undefined}>
        {icon && (
          <figure className='navpanel__item-icon'>
            <svg className='icon' aria-hidden='true'>
              <use xlinkHref={`#navigation--mityousee--${icon}`}></use>
            </svg>
          </figure>
        )}
        {text}
      </a>
    </li>
  );
};

export const NavParentItem = ({
  text,
  link,
  children,
  icon,
  spinningIcon = false,
}: {
  text: string;
  link: string;
  children: ReactNode;
  icon?: string;
  spinningIcon?: boolean;
}) => {
  const [isActive, setIsActive] = useState(false);
  const navId = 'nav-selfcare';

  useEffect(() => {
    const unbind = events.on('close', (side) => {
      if (side === navId) setIsActive(false);
    });

    return unbind;
  }, []);

  const enter = (e) => {
    e.preventDefault();
    leftNavBtn?.slide(1);
    setIsActive(true);
  };

  const goBack = (e) => {
    e.preventDefault();
    leftNavBtn?.slide(-1);
    AnimatedNavPanelLinks();
    // Set the remove function
    const removeClass = () => {
      setIsActive(false);

      leftNavBtn?.domNavPanelListElement?.removeEventListener(
        transitionEndEvent,
        removeClass,
        false,
      );
    };

    leftNavBtn?.domNavPanelListElement?.addEventListener(transitionEndEvent, removeClass, false);
  };

  return (
    <li
      className={cx('navpanel__item navpanel-item-is-parent', {
        'navpanel-item-is-active': isActive,
      })}>
      <a
        className={cx('navpanel__item-link', { 'navpanel--scrolly': isActive })}
        href={link}
        onClick={enter}>
        {icon && (
          <figure
            className={cx('navpanel__item-icon', {
              [styles.spinningIcon]: spinningIcon,
            })}>
            <svg className='icon' aria-hidden='true'>
              <use xlinkHref={`#navigation--mityousee--${icon}`}></use>
            </svg>
          </figure>
        )}
        {text}
        <span className='navpanel__item-link-arrow'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'>
            <path
              className='navpanel__item-link-arrow-props navpanel-arrow'
              d='M16,9H16'
              style={{ opacity: 0 }}
            />
            <path className='navpanel__item-link-arrow-props' d='M9,1l8,8-8,8' />
          </svg>
        </span>
      </a>
      <ul className='navpanel__items'>
        <li className='navpanel__item navpanel__item--header'>
          <a
            className='navpanel__item-link navpanel__item-link--header'
            aria-label={`Tilbage til ${text}`}
            href={link}
            onClick={goBack}>
            {text}
            <span className='navpanel__item-link-arrow'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'>
                <path
                  className='navpanel__item-link-arrow-props navpanel-arrow'
                  d='M1,9H16'
                  style={{ opacity: 0 }}
                />
                <path className='navpanel__item-link-arrow-props' d='M9,1,1,9l8,8' />
              </svg>
            </span>
          </a>
        </li>
        {isActive && children}
      </ul>
    </li>
  );
};
