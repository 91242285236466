import { Address } from 'shared/interfaces/address';

/**
 * Formats address object to human-readable string.
 * @param address Address object, usually from API response.
 * @param inclCity Should include zip code and city in address string.
 * @returns Address.
 */
export function formatAddress(address: Address, inclCity = false): string {
  if (!address) {
    throw new Error('Null or undefined address object was passed.');
  }

  const { street, streetNumber, houseLetter, level, side, apartmentNumber, zipCode, city } =
    address;
  let formattedAddress = street;

  formattedAddress += streetNumber ? ` ${streetNumber}` : '';
  formattedAddress += houseLetter ? ` ${houseLetter}` : '';

  if (level) {
    formattedAddress += `, ${level}`;
    formattedAddress += side ? ` ${side}` : apartmentNumber ? ` ${apartmentNumber}` : '';
  }

  if (inclCity) {
    formattedAddress += zipCode ? `, ${zipCode}` : '';
    formattedAddress += city ? ` ${city}` : '';
  }

  return formattedAddress;
}

export const address = { address: formatAddress };
