import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system/styleFunctionSx';
import { colors } from '@nuuday/yousee-dna/generated/colors';

export const pageHeaderSx: SxProps<Theme> = {
  bgcolor: colors.White,
  mb: 3,
  py: 5,
  px: 0,
};

export const pageHeaderMobileSx: SxProps<Theme> = {
  pt: 4,
  pb: 4,
};
