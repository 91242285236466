import { getFeatureToggle } from '../feature-toggle';
import { getParsedToken } from './getParsedToken';
import { getStack } from './getStack';

/**
 * Method that returns HTML IDs for a given string. Special characters are removed and the string is
 * converted to lower case. Spaces are replaced with dashes.
 */
export const getHtmlId = (str: string): string | undefined => {
  return str
    ? str
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .replace(/ /g, '-')
        .toLowerCase()
    : undefined;
};

/**
 * Returns true if the currently logged in user is part of a split stack (i.e. has products both in
 * the new Netcracker system, and the old YouSee system). Additionally, the feature toggle
 * feat-netcracker can be used to force the split stack to be enabled or disabled.
 */

export enum StackNames {
  NETCRACKER = 'feat-netcracker',
  COLUMBUS = 'feat-columbus',
  DUAL = 'feat-dual',
}

export const isNetcracker = getFeatureToggle({ name: 'feat-netcracker' });
export const isColumbus = getFeatureToggle({ name: 'feat-columbus' });
export const isDual = isNetcracker && isColumbus;

export const getStackSimulated = (defaultStack: StackNames): StackNames => {
  if (isNetcracker && !isDual) {
    return StackNames.NETCRACKER;
  }

  if (isColumbus && !isDual) {
    return StackNames.COLUMBUS;
  }

  if (isDual) {
    return StackNames.DUAL;
  }
  return defaultStack;
};

export const hasOnlyNetCrackerProducts = (): boolean => {
  return getStack() === StackNames.NETCRACKER;
};

export const hasOnlyColumbusProducts = (): boolean => {
  return getStack() === StackNames.COLUMBUS;
};

export const hasDualStackProducts = (): boolean => {
  return getStack() === StackNames.DUAL;
};

export const getIndividualId = (): string | undefined => {
  const parsedToken = getParsedToken();
  return parsedToken?.individual_id;
};

export const getLinkItId = (): string | undefined => {
  const parsedToken = getParsedToken();
  return parsedToken?.linkItId || parsedToken?.linkitid;
};
