export const getItem = (key) => {
  const session = window.sessionStorage;
  return session.getItem(key);
};

export const setItem = (key, value) => {
  const session = window.sessionStorage;
  session.setItem(key, value);
};

export const removeItem = (key) => {
  const session = window.sessionStorage;
  session.removeItem(key);
};

export const getState = () => {
  return getItem('state');
};

export const getCodeVerifier = () => {
  const codeVerifier = getItem('code_verifier');
  removeItem('code_verifier');
  return codeVerifier;
};

export const saveStateAndVerifier = (state, codeVerifier) => {
  setItem('state', state);
  setItem('code_verifier', codeVerifier);
};
