import { event, view } from '@nuuday/data-layer';

import { useMyCustomers } from 'api/customers';
import { useEffect } from 'react';
import { TrackingEventData } from './types';

export const initialize = () => {
  const adobeTrackingScript = document.createElement('script');
  adobeTrackingScript.setAttribute(
    'src',
    `https://assets.adobedtm.com/0925f9fe4554/db034fc7b9f7/launch-${
      import.meta.env.PUBLIC_ADOBE_LAUNCH_ENV_KEY
    }.min.js`,
  );
  adobeTrackingScript.setAttribute('async', 'true');
  document.body.appendChild(adobeTrackingScript);
};

/**
 * Track page views based on the page name and any additional data
 * Corresponds to Page Load in Adobe Launch.
 */
export const useTrackPageView = ({ pageName, ...data }: TrackingEventData) => {
  const { data: customerData } = useMyCustomers();
  useEffect(() => {
    if (!customerData) {
      return;
    }
    try {
      view({
        pageName,
        // linkkundeid is the same as linkitid - just a danglish name that analytics uses
        linkkundeid: customerData.map((account) => account.id),
        siteSections: ['I hjemmet', 'selvbetjening'],
        pageType: 'selvbetjening',
        ...data,
      });
    } catch (e) {
      // if the tracking fails we can't do anything about it
    }
  }, [pageName, data, customerData]);
};

/**
 * Track events based on the page name and any additional data
 * Corresponds to Link in Adobe Launch
 */
export const trackEvent = ({ pageName, ...data }: TrackingEventData) => {
  try {
    event({
      pageName,
      pageType: 'selvbetjening',
      ...data,
    });
  } catch (e) {
    // if the tracking fails we can't do anything about it
  }
};
