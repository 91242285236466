export let identity: {
  userId?: number;
  username?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  isLoggedIn?: boolean;
  isCheckingAuth?: boolean;
} = {
  userId: 0,
  username: '',
  firstname: '',
  lastname: '',
  isLoggedIn: false,
  isCheckingAuth: true,
};

export const setIdentity = (data: typeof identity) => (identity = data);
export const getIdentity = (): Required<typeof identity> => {
  if (identity.userId === 0) {
    throw new Error('Identity is not set');
  }

  return identity as Required<typeof identity>;
};
