import {
  NetCrackerProductCharacteristicType,
  NetCrackerProductType,
  NetCrackerSubscriptionType,
} from 'api/netCrackerAPI/netCrackerApiTypes';
import {
  getAddress,
  getPhoneNumber,
  getProductName,
  getProductType,
  getUrl,
  hasPhoneNumber,
} from 'hooks/useMyColumbusSubscriptions/useMyColumbusSubscriptionsUtils';
import { getConfig } from 'shared/config';
import { post } from 'shared/utils/api';
import { getIndividualId } from 'utils/misc';

const baseHost = 'CUSTOM';

const MobileProductTypes = {
  '5d8ec1da-d4d7-430d-908f-8143cccb0e8b': 'Mobile',
  '9f6e0aec-6e65-4507-af0e-5964dd37f056': 'Mobile',
  '6c69770c-d1b6-4e44-aaeb-e58cf3583ed8': 'MobileBroadband',
  '74b03cde-54ef-425c-a6fb-d5957ebed88b': 'MobileBroadband',
};
const PhoneNumberProductType = '0d707568-6ce6-4841-8436-cde84c23b780';

export const getListCustomersByIndividualId = async () => {
  const listCustomersByIndividualIdQuery = `query listCustomersByIndividualId($individualIdFilter: String!) {
    listCustomers @filter(filters: [$individualIdFilter, "engagedParty.@referredType=Individual"]) {
       id
      }
  }`;

  const listCustomersVariables = {
    individualIdFilter: `engagedParty.id=${getIndividualId()}`,
  };

  const listCustomersBody = JSON.stringify({
    query: listCustomersByIndividualIdQuery,
    variables: listCustomersVariables,
  });

  return postToGraphQl(listCustomersBody).then((res) => res?.data?.listCustomers?.[0].id);
};

export const getProductListCustomersId = async (customerId: string) => {
  const productListQuery = `query listProducts($customerId: ID!) {
    listProducts(customerId: $customerId)
      @limit(limit: 100)
      @filter(
        filters: [
          "status=ACTIVE,COMPLETED,PLANNED,ACTIVE_INSTALLMENT,ACTIVATION_IN_PROGRESS,WAITING_FOR_PROCESSING,MODIFICATION_IN_PROGRESS,SUSPENDED,DISCONNECTION_IN_PROGRESS"
        ]
      ) {
      id
      name
      status
      parentProductId
      productFamily {
        id
      }
      characteristic {
        id
        name
        value
        businessType
      }
    }
  }`;

  const productListQuerVariables = {
    customerId,
  };

  const productListBody = JSON.stringify({
    query: productListQuery,
    variables: productListQuerVariables,
  });

  return postToGraphQl(productListBody).then(({ data }) => {
    if (!data.listProducts) {
      return [];
    }
    const products = data.listProducts.filter(
      (product) => MobileProductTypes[product.productFamily.id] ?? null,
    );
    const helpers = data.listProducts.filter(
      (product) => product.productFamily.id === PhoneNumberProductType ?? null,
    );

    return mapDawnProduct(products, helpers);
  });
};

const postToGraphQl = async (data: string): Promise<any> => {
  const netCrackerHost = getConfig().netcracker?.host;
  const netCrackerGraphQlEndpoint = getConfig().netcracker?.graphQlEndpoint;
  const netCrackerUrl = `${netCrackerHost}${netCrackerGraphQlEndpoint}`;

  return post({
    url: netCrackerUrl,
    baseHost,
    requireAuth: true,
    data,
  });
};

const findCharacteristic = (product, key) =>
  product.characteristic?.find((characteristic) => characteristic.businessType === key);

const mapDawnProduct = (
  listProducts: NetCrackerProductType[],
  helpers?: NetCrackerProductType[],
): NetCrackerSubscriptionType[] => {
  const netCrackerProducts: NetCrackerProductType[] = listProducts;
  const netCrackerSubscriptions: NetCrackerSubscriptionType[] = [];
  const phoneNumberKey = 'PHONE_NUMBER';

  netCrackerProducts.forEach((product) => {
    const productType = MobileProductTypes[product.productFamily.id] ?? null;
    const characteristic = findCharacteristic(product, phoneNumberKey);
    let helperCharactristic;
    if (!characteristic) {
      const helper = helpers?.find((item) => item.parentProductId === product.id);

      helperCharactristic = findCharacteristic(helper, phoneNumberKey);
    }
    netCrackerSubscriptions.push(
      formatNetCrackerSubscription(product.id, productType, characteristic || helperCharactristic),
    );
  });

  return netCrackerSubscriptions;
};

const formatNetCrackerSubscription = (
  subscriptionId: string,
  productType: string,
  characteristic?: NetCrackerProductCharacteristicType,
): NetCrackerSubscriptionType => {
  const phoneNumber = characteristic
    ? characteristic.value[0][0] === '+'
      ? characteristic.value[0].substring(1)
      : characteristic.value[0]
    : '';

  const formattedNetCrackerSubscription: NetCrackerSubscriptionType = {
    isNetCrackerSubscription: true,
    subscriptionId,
    productType: getProductType(productType),
    productName: getProductName(productType),
    phoneNumber,
    value: phoneNumber,
    getAddress: function (): string {
      return getAddress(this.address);
    },
    hasPhoneNumber: function (): boolean {
      return hasPhoneNumber(this);
    },
    getPhoneNumber: function (): string {
      return getPhoneNumber(this, this.phoneNumber);
    },
    getUrl: function (): string {
      return getUrl([], this.productType, this, this.isNetCrackerSubscription);
    },
  };

  return formattedNetCrackerSubscription;
};
