import Request from '../lib/request';

export function noShortcuts() {
  // fix for shortcuts if it contains no children
  const domSelfcare = document.getElementById('nav-selfcare');
  if (domSelfcare) {
    const domSelfcareShortcuts = domSelfcare.getElementsByClassName('navshortcuts')[0];

    if (domSelfcareShortcuts.getElementsByTagName('li').length === 0) {
      domSelfcareShortcuts.classList.add('navshortcuts--no-shortcuts');
      domSelfcare
        .getElementsByClassName('navpanel-items-level0')[0]
        .classList.add('navpanel--no-shortcuts');
    }
  }
}

function ShortcutsPlugin() {
  const domSelfcare = document.getElementById('nav-selfcare');
  if (domSelfcare) {
    const domSelfcareShortcuts = domSelfcare.getElementsByClassName('navshortcuts')[0];
    domSelfcareShortcuts.classList.add('navshortcuts--no-shortcuts');

    Request(
      'hasserviceaccess',
      (allAccess) => {
        const accessKeys = Object.keys(allAccess).filter((key) => allAccess[key]);
        //MAKE SURE WE HAVE ACCESS BEFORE WE DO ANYTHING!
        if (accessKeys && accessKeys.length > 0) {
          const shortcuts = domSelfcareShortcuts.getElementsByTagName('li');
          if (shortcuts && shortcuts.length > 0) {
            for (let i = 0; shortcuts.length > i; i++) {
              if (
                !allAccess.hasYouseeMusik &&
                shortcuts[i] &&
                shortcuts[i].getElementsByTagName('a')[0].href.indexOf('musik') > -1
              ) {
                shortcuts[i].remove();
              }

              if (
                !allAccess.hasWebmail &&
                shortcuts[i] &&
                shortcuts[i].getElementsByTagName('a')[0].href.indexOf('mail') > -1
              ) {
                shortcuts[i].remove();
              }

              if (
                !allAccess.hasTv &&
                shortcuts[i] &&
                shortcuts[i].getElementsByTagName('a')[0].href.indexOf('tv') > -1
              ) {
                shortcuts[i].remove();
              }
            }
            domSelfcareShortcuts.classList.remove('navshortcuts--no-shortcuts');
          }
        }
      },
      (err) => {
        //REMOVE ALL SHORTCUTS IF ANYTHING GOES WRONG
        console.warn(err);

        const shortcuts = domSelfcareShortcuts.getElementsByTagName('li');
        for (let i = 0; shortcuts.length > i; i++) {
          domSelfcareShortcuts.removeChild(shortcuts[i]);
        }
        noShortcuts();
      },
    );
  }
}

export default ShortcutsPlugin;
