import { Card as MuiCard } from '@mui/material';
import { Theme } from '@mui/system/createTheme/createTheme';
import { SxProps } from '@mui/system/styleFunctionSx';
import React from 'react';

interface CardProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const Card = ({ children, sx, ...rest }: CardProps) => {
  return (
    <MuiCard
      sx={{
        mb: 4,
        ['&.MuiCard-root']: {
          p: 0,
        },
        ...sx,
      }}
      {...rest}>
      {children}
    </MuiCard>
  );
};
